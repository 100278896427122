.provider-select {
  column-gap: 1.5rem;
  display: grid;
  grid-column: span 12;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 3rem;

  &__clinic-container {
    grid-column: span 12;
    margin-top: -1rem;
  }

  &__clinic,
  &__provider {
    margin-bottom: 0;
  }

  &__provider-container {
    grid-column: span 12;
  }

  &__heading {
    grid-column: span 12;
  }

  &__question {
    grid-column: span 9;
    margin-top: -2.5rem;

    @media screen and (max-width: 768px) {
      grid-column: span 12;
    }
  }

  &__help {
    grid-column: span 9;

    @media screen and (max-width: 768px) {
      grid-column: span 12;
    }
  }

  & .continue {
    grid-column: span 4;

    @media screen and (max-width: 768px) {
      grid-column: span 5;
    }

    @media screen and (max-width: 530px) {
      grid-column: span 12;
    }
  }
}
