.option-select {
  --select-padding-l: 1.125rem;
  --select-padding-r: 10%;
  --select-border-width: 0.25rem;

  display: flex;
  flex-direction: column;
  grid-column: span 12;

  &__select-input {
    appearance: none;
    background: url("../../../assets/arrow-down.svg") 95.44% / 0.7rem no-repeat
      var(--white);
    border-width: var(--select-border-width);
    border-style: solid;
    border-color: var(--neutral002);
    box-sizing: border-box;
    font-size: 1.375rem;
    font-weight: 500;
    height: 4rem;
    padding-left: var(--select-padding-l);
    padding-right: var(--select-padding-r);
    letter-spacing: 0.01em;
    line-height: 1.75rem;
    text-overflow: clip;
    width: 100%;

    &--super-small {
      grid-column: span 3;
    }

    &--x-small {
      grid-column: span 4;
    }

    &--small {
      grid-column: span 5;
    }

    &--medium {
      grid-column: span 6;
    }

    &--large {
      grid-column: span 9;
    }

    &--x-large {
      grid-column: span 12;
    }

    @media screen and (min-width: 530px) and (max-width: 768px) {
      &--super-small {
        grid-column: span 4;
      }

      &--x-small {
        grid-column: span 5;
      }

      &--small {
        grid-column: span 6;
      }

      &--medium {
        grid-column: span 12;
      }

      &--large {
        grid-column: span 12;
      }

      &--x-large {
        grid-column: span 12;
      }
    }
    @media screen and (max-width: 529px) {
      &--super-small {
        grid-column: span 12;
      }

      &--x-small {
        grid-column: span 12;
      }

      &--small {
        grid-column: span 12;
      }

      &--medium {
        grid-column: span 12;
      }

      &--large {
        grid-column: span 12;
      }

      &--x-large {
        grid-column: span 12;
      }
    }

    &--selected {
      border-color: var(--primary003);
    }

    &--error {
      border-left-style: none; // hide because borders are always mitered
      border-right-style: none; // hide because borders are always mitered
      padding-left: calc(var(--select-padding-l) + var(--select-border-width));
      padding-right: calc(var(--select-padding-r) + var(--select-border-width));
      border-bottom-width: 0.25rem;
      border-bottom-color: var(--error);
      &:focus {
        border-bottom-color: var(--error);
      }
    }

    &:focus {
      outline: none;
      box-shadow: var(--box-shadow);
    }
  }

  &__question {
    margin-bottom: 2rem;

    &--select {
      margin-bottom: 0;
    }
  }

  &__subtitle {
    display: block;
    margin-top: 2rem;
  }

  &__subtitle--native {
    margin-bottom: 0.05rem;
  }

  &__options {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1.5rem;
    row-gap: 3rem;
  }

  &__label-container {
    display: flex;
    justify-content: space-between;
  }

  &__required-label {
    color: var(--error);
    text-align: right;
    width: 100%;
  }

  &__not-sure-button {
    margin-top: 2rem;
  }
}
