.selection-option {
  cursor: pointer;
  outline: none;
  border-style: solid;
  border-color: var(--neutral002);
  background-color: var(--white);
  color: var(--neutral001);
  min-height: 3rem;
  display: flex;

  &--x-large {
    grid-column: span 12;
  }

  &--large {
    grid-column: span 9;

    @media screen and (max-width: 768px) {
      grid-column: span 12;
    }

    @media screen and (max-width: 530px) {
      grid-column: span 12;
    }
  }

  &--medium {
    grid-column: span 6;

    @media screen and (max-width: 768px) {
      grid-column: span 12;
    }

    @media screen and (max-width: 530px) {
      grid-column: span 12;
    }
  }

  &--small {
    grid-column: span 5;

    @media screen and (max-width: 768px) {
      grid-column: span 6;
    }

    @media screen and (max-width: 530px) {
      grid-column: span 12;
    }
  }

  &--x-small {
    grid-column: span 4;

    @media screen and (max-width: 768px) {
      grid-column: span 5;
    }

    @media screen and (max-width: 530px) {
      grid-column: span 12;
    }
  }

  &--super-small {
    grid-column: span 3;

    @media screen and (max-width: 768px) {
      grid-column: span 4;
    }

    @media screen and (max-width: 530px) {
      grid-column: span 12;
    }
  }

  &__label {
    cursor: pointer;
    padding: 1rem;
    margin: auto;
    word-break: break-word;
    text-align: center;
  }

  &:hover {
    border-color: var(--primary004);
    box-shadow: var(--box-shadow);
  }

  &:focus {
    border-color: var(--neutral002);
    box-shadow: var(--box-shadow);
  }

  &--left {
    text-align: left;
  }

  &--selected {
    background-color: var(--primary006);
    border-color: var(--primary001);

    &:focus {
      border-color: var(--primary001);
    }
  }

  &--error {
    border-color: var(--error);
  }

  &--checkbox {
    display: block;
    border: none;

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &.selection-option--selected {
      background: transparent;

      .selection-option__label > svg {
        height: 21px;
        width: 21px;
        min-width: 21px;
        margin-right: 28px;
        margin-left: 1px;
        margin-top: 4px;
      }
    }

    .selection-option__label {
      display: flex;
      padding: 0;
      box-shadow: none;
      align-items: flex-start;

      > svg {
        height: 28px;
        width: 28px;
        min-width: 28px;
        margin-left: -2px;
        color: var(--primary004);
        margin-right: 25px;
      }
    }
  }
  &--primary {
    background: var(--primary009);
    border: 2px solid var(--primary009);
    color: white;
  }
}
