@import './src/themes/variables';

.card {
  display: grid;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  align-self: baseline;
  text-align: left;

  @media screen and (min-width: $mq-sm) and (max-width: $mq-md) {
    flex-grow: initial;
    flex-basis: initial;
    width: 49%;
  }

  &:first-child {
    margin-left: 0;
  }

  @media (min-width: $mq-md) {
    margin-left: 35px;
  }

  &--alternative {
    box-shadow: var(--box-shadow002);
    border-radius: 7px;
    margin-bottom: 15px;
    align-self: stretch;
    margin-left: 0;

    &:first-child {
      margin-left: 0;
    }

    @media (min-width: $mq-md) {
      margin-left: 11px;
    }

    &:after {
      content: '';
      height: 7px;
      background: var(--primary007);
      border-radius: 0 0 7px 7px;
      align-self: self-end;
    }

    .card__text,
    .card__title {
      padding: 0 12px;
    }

    .card__image-wrapper {
      border-radius: 7px 7px 0 0;
      overflow: hidden;
    }
  }

  &--simple {
    display: grid;
    grid-template-columns: 40% 60%;
    align-items: stretch;
    margin-bottom: 15px;

    @media screen and (min-width: $mq-sm) and (max-width: $mq-md) {
      width: auto;
    }

    @media screen and (min-width: $mq-sm) {
      grid-template-columns: 25% 75%;
    }

    .card__title {
      font-size: var(--font-size-text-large);
      line-height: var(--line-height-small);
      background-color: var(--blue-lighter);
      text-align: center;
      padding: 14px;
      border-radius: var(--border-radius001) 0 0 var(--border-radius001);

      &-text {
        font-weight: var(--font-weight-bolder);
        font-size: var(--font-size-text);
      }
    }

    .card__text {
      padding: 24px 24px 24px 32px;
      margin: 0;
      background-color: var(--blue);
      border-radius: 0 var(--border-radius001) var(--border-radius001) 0;
    }
  }

  &__image-wrapper {
    margin: 0 auto 25px auto;
    width: 100%;
    height: 191px;
    padding: 60px;
    background-color: var(--lighter-gray);
    display: flex;
    align-items: center;
    box-sizing: border-box;

    &-provider {
      padding: 0;
      background: transparent;
      width: auto;

      > img {
        max-width: 100%;
        max-height: 100%;
      }

      &.placeholder {
        width: 100%;

        > img {
          max-width: none;
          height: 100%;
        }
      }
    }

    @media (min-width: $mq-md) {
      max-height: 191px;
    }
  }

  &__image {
    width: 100%;
    object-fit: cover;
    max-width: 300px;
    margin: 0 auto;
  }

  &__title {
    font-weight: var(--font-weight-bold);
    font-family: var(--font-family001);
    font-size: var(--font-size-subtitles);
    line-height: var(--line-height-small);
    margin: 0;
    color: var(--primary007);
    display: flex;
    align-items: center;

    > img {
      height: 24px;
      margin-right: 10px;
    }
  }

  &__text {
    color: var(--text-color);
    font-size: var(--font-size-normal);
    line-height: var(--line-height-small-normal);
    margin-bottom: 38px;
    margin-top: 10px;
  }

  &__button-link {
    color: var(--primary007);
    transition: all 0.25s ease;
    cursor: pointer;

    &:hover {
      color: var(--primary008);
    }
  }
}

