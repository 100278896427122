@import './src/themes/variables';

$content-padding: 64px;
$content-padding-xs: calc(#{$content-padding} / 2);
$content-margin: 12px;

@mixin buttonStyle {
  color: var(--white);
  letter-spacing: -0.01em;
  background-color: var(--primary007);
  border-radius: var(--border-radius);
  border: 2px solid transparent;
  padding: 15px;
  transition: all 0.25s ease;
  width: auto;
  min-width: 165px;

  .text {
    font-size: var(--font-size-normal);
    font-family: var(--font-family001);
    font-weight: var(--font-weight-bold);
    line-height: normal;
  }

  &:focus,
  &:hover {
    box-shadow: none;
    background-color: var(--primary008);
  }
}

// virtualCare theme

.survey-container--virtualCare {
  margin-top: var(--spacing18);
}

.modal--virtualCare {
  .modal__footer {
    display: none;
  }
  .modal__backdrop {
    background: var(--overlay-moda001);
  }

  .modal__content-area {
    background: var(--white);
    box-shadow: var(--box-shadow005);
    border-radius: var(--border-radius001);
    padding: 0;
    overflow-y: auto;
  }

  .modal__header {
    position: absolute;
    right: calc(#{$content-padding-xs} / 3);
    top: calc(#{$content-padding-xs} / 3);

    @media screen and (min-width: $mq-md) {
      right: calc(#{$content-padding-xs} / 2);
      top: calc(#{$content-padding-xs} / 2);
    }
  }

  .modal__close-icon {
    color: var(--text-color);
  }

  .redirect-node,
  .countdown-page__actions {
    padding: $content-padding-xs;
    justify-content: center;

    @media screen and (min-width: $mq-md) {
      padding: $content-padding;
    }
  }

  .countdown-page {
    width: auto;
    margin: 0;
  }

  .yes-no__btn,
  .continue__button,
  .selection-option__label,
  .countdown-page__continue-button,
  .countdown-page__start-over-button {
    border-color: transparent;
    @include buttonStyle;
  }

  .selection-option__label {
    min-width: 180px;
  }

  .selection-option {
    border: 0;
    margin: $content-margin 0;

    &:hover {
      box-shadow: none;
    }

    &__label {
      margin: 0;
    }
  }

  .selection-option--selected {
    background: transparent;
  }

  .yes-no_btn--no {
    background-color: var(--white);
    color: var(--primary007);
    border-color: var(--primary007);

    &:focus, &:hover {
      color: var(--white);
      background-color: var(--primary007);
    }
  }

  .resultsOptOut-answerKey:nth-child(2) {
    .selection-option {
      margin-top: -20px;
      padding-bottom: 20px;

      @media screen and (min-width: $mq-md) {
        margin-top: -40px;
        padding-bottom: 30px;
      }

      @media screen and (min-width: $mq-lg) {
        padding-bottom: 90px;
        margin-top: -20px;
      }
    }

    .selection-option__label {
      background-color: var(--white);
      color: var(--primary007);
      border-color: var(--primary007);
      position: relative;
      min-width: 200px;

      &:focus, &:hover {
        color: var(--white);
        background-color: var(--primary007);
      }
    }
  }

  .page-node__content .title.title--1,
  .page-node__subtitle,
  .provider-select__heading,
  .countdown-page__title {
    padding: $content-padding-xs;
    background: rgba(0, 113, 206, 0.1);
    border-radius: var(--border-radius001) var(--border-radius001) 0 0;
    width: 100%;
    color: var(--primary007);
    grid-column: span 12;
    margin-bottom: $content-padding-xs;
    font-weight: var(--font-weight-bold);
    font-family: var(--font-family001);
    font-size: var(--font-size-subtitles);

    @media screen and (min-width: $mq-md) {
      margin-bottom: $content-padding;
      padding: $content-padding;
      font-size: var(--font-size-titles);
    }
  }

  .countdown-page__title {
    width: auto;
  }

  .page-node__subtitle,
  .countdown-page__title {
    font-size: var(--font-size-subtitles);
    font-family: var(--font-family001);
    letter-spacing: -0.02em;
    font-weight: var(--font-weight-bold);

    @media screen and (min-width: $mq-md) {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }

  .page-node--columns .page-node__content {
    padding: 0 $content-padding-xs $content-padding-xs 0;

    @media screen and (min-width: $mq-md) {
      padding: 0 $content-padding $content-padding 0;
    }

    .text--1 + .page-node__action {
      @media screen and (min-width: $mq-lg) {
        margin-bottom: 150px;
      }
    }
  }

  .title--3,
  .provider-select__question {
    color: var(--primary007);
    font-size: var(--font-size-text);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-small);
    font-family: var(--font-family001);
    letter-spacing: -0.01em;
    margin-bottom: $content-margin;

    @media screen and (min-width: $mq-md) {
      font-size: var(--font-size-subtitles);
    }
  }

  .yes-no__btns {
    display: block;
  }

  .yes-no__btn {
    margin-right: 11px;
    margin-bottom: $content-margin;

    @media screen and (min-width: $mq-md) {
      margin-bottom: 0;
    }
  }

  .text--3 {
    @media screen and (min-width: $mq-md) {
      display: flex;
    }

    .text-content__highlight {
      background-color: var(--blue-lighter);
      border-radius: var(--border-radius001) 0 0 var(--border-radius001);
      padding: 16px;
      color: var(--primary007);
      min-width: 120px;
      align-items: center;
      text-align: center;
      display: block;

      @media screen and (min-width: $mq-md) {
        display: flex;
      }
    }

    b {
      background: var(--blue-light);
      border-radius: 0 var(--border-radius001) var(--border-radius001) 0;
      padding: 16px;
      font-weight: var(--font-weight-normal);
      display: block;
    }
  }

  .page-node--columns .page-node__content {
    padding: 0 calc(#{$content-padding-xs} / 2);

    @media screen and (min-width: $mq-md) {
      padding: 0 $content-padding-xs;
    }

    > .title,
    > .text-list,
    > .help-hint,
    > .text,
    > .yes-no,
    > .page-node__action--continue,
    > .option-select,
    > .text--3 {
      padding: 0 calc(#{$content-padding-xs} / 2);
    }
  }

  .page-node__content {
    display: block;

    .grid {
      margin: 25px 0;

      @media screen and (min-width: $mq-sm) {
        margin-top: 0;
      }

      &__title {
        display: none;
      }

      &__items {
        margin-top: -30px;

        @media screen and (min-width: $mq-sm) {
          grid-template-columns: 20% 1fr 1fr;
          margin-top: -10px;
        }

        @media screen and (min-width: $mq-md) {
          margin-top: -30px;
        }

        @media screen and (min-width: $mq-lg) {
          min-width: 1000px;
          grid-template-columns: 12% 1fr 1fr;
        }
      }
    }

    .grid__item {
      &.cell {
        background-color: var(--blue);
      }

      &.label {
        background-color: var(--blue-lighter);
        color: var(--primary007);

        @media screen and (min-width: $mq-sm) {
          border-radius: var(--border-radius001) 0 0 var(--border-radius001);
        }
      }
    }

    > .text,
    > .help-hint {
      margin-bottom: $content-padding-xs;

      @media screen and (min-width: $mq-md) {
        margin-bottom: $content-padding;
      }
    }

    > .text--3 + .text--3 {
      padding-bottom: $content-padding-xs;
      margin-bottom: 0;

      @media screen and (min-width: $mq-md) {
        padding-bottom: $content-padding;
      }
    }

    > .text-list {
      margin-bottom: calc(#{$content-padding-xs} / 2);

      @media screen and (min-width: $mq-md) {
        margin-bottom: $content-padding-xs;
      }
    }

    > .text--3 {
      margin-bottom: $content-margin;
    }

    > .title,
    > .text-list,
    > .grid,
    > .help-hint,
    > .text,
    > .yes-no,
    > .page-node__action--continue,
    > .option-select,
    > .text--3 {
      padding: 0 $content-padding-xs;

      @media screen and (min-width: $mq-md) {
        padding: 0 $content-padding;
      }
    }

    > .help-hint .text {
      font-style: italic;
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-note);
    }

    > .text,
    .text-list__list .text {
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-normal);
      line-height: var(--line-height-normal);
      color: var(--text-color);
    }

    .text-list__list {
      padding-left: 15px;

      &--columned {
        @media screen and (min-width: $mq-sm) {
          display: block;
          column-count: 2;
        }
      }
    }
  }

  .provider-select {
    display: block;

    > .text  {
      margin-bottom: $content-padding-xs;

      @media screen and (min-width: $mq-md) {
        margin-bottom: $content-padding;
      }
    }

    > .text-list,
    > .help-hint,
    > .text,
    > .yes-no,
    > .page-node__action--continue,
    > .option-select,
    > .text--3 {
      padding: 0 $content-padding-xs;

      @media screen and (min-width: $mq-md) {
        padding: 0 $content-padding;
      }
    }

    .provider-select__question {
      padding-top: $content-padding-xs;
      margin-bottom: 25px;
    }

    .provider-select__heading {
      max-width: 100%;
      width: auto;
    }

    .provider-select__provider-option-select {
      @media screen and (min-width: $mq-md) {
        max-width: 65%;
      }

      label {
        padding-bottom: 15px;
        font-size: var(--font-size-text);
      }

      .option-select__select-input {
        border: 2px solid var(--gray);
        border-radius: var(--border-radius);
        color: var(--text-color-light);
        font-style: italic;
        box-shadow: none;
        padding: 12px;
        line-height: 1.5em;
        font-size: var(--font-size-text);
        height: auto;
        margin-bottom: $content-padding-xs;

        @media screen and (min-width: $mq-md) {
          margin-bottom: $content-padding;
        }
      }
    }

    .continue {
      padding: 0 $content-padding-xs;
      display: block;

      @media screen and (min-width: $mq-sm) {
        padding: 0 0 $content-padding-xs $content-padding-xs;
        display: inline-block;
      }

      @media screen and (min-width: $mq-md) {
        padding: 0 0 $content-padding $content-padding;
      }

      + .continue {
        padding-top: 15px;
        padding-bottom: $content-padding-xs;

        @media screen and (min-width: $mq-sm) {
          padding-left: 15px;
          padding-bottom: $content-padding-xs;
        }
      }
    }
  }
}
