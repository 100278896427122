@import '@myriadgenetics/tokens/dist/tokens.css';

.survey-container {

  &--classic,
  &--default {
    background: white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    
    @media screen and (min-width: 768px) {
      background: var(--myd-color-gradient-white-greyblue);
    }
  }

  &__main {
    margin: 0;

    &--modal-view {
      min-height: auto;

      .survey-container__node {
        padding: 0;
        margin: 0;
      }
    }
  }

  &__logo {
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
  }

  &__patient-demographics {
    text-align: end;
    margin-top: var(--spacing13);
  }

  &__dtc-progress {
    max-width: 29.125rem;
    width: 100%;
    margin: auto;
    padding-bottom: 1.33rem;
  }

  &__top {
    position: absolute;
    top: 0;
    width: 100%;
    height: 3.75rem;
    background-color: var(--neutral003);
  }

  &__header-nav-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__header-nav {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__back {
    padding: var(--myd-advanced-dropdown-spacing-dropdown-topbottom) var(--myd-advanced-dropdown-spacing-dropdown-leftright);
    margin-top: var(--myd-advanced-dropdown-spacing-dropdown-topbottom);
  }

  &__lang-toggle {
    position: absolute;
    top: 0.25rem;
    right: 0.5rem;
  }

  &__review,
  &__process {
    margin: auto;
    text-align: center;
  }

  &__results {
    margin-bottom: 10rem;

    @media screen and (min-width: 768px) {
      margin-bottom: 11rem;
    }

    @media screen and (min-width: 1200px) {
      margin-bottom: 17rem;
    }

    & .image {
      margin-top: 3rem;
    }

    & .page-node {
      &__content {
        display: block;
        margin: 0;

        &:last-child {
          margin-bottom: 7.5rem;
        }
      }

      &__action {
        margin-bottom: 3rem;
        width: 15rem;
      }
      // Temporary fix to improve spacing on the dnavisits results page.
      // TODO - Remove once mgh-pilot-main is deployed.
      &[data-testid*='dnavisit'] {
        .page-node__content {
          display: grid;
        }
      }
    }
  }

  &__provider {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1.5rem;
    row-gap: 3rem;
  }

  &__node {
    margin: auto;
    margin-top: 6rem;
    max-width: 48rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
  
    @media screen and (min-width: 1200px) {
      max-width: 75rem;
    }

    @media screen and (max-width: 530px) {
      max-width: 33.125rem;
    }
  }

  &__demo-indicator{
    text-align: center;
    color: var(--white);
    background-color: var(--primary004);
    padding-top: var(--spacing3);
    padding-bottom: var(--spacing6);
    z-index: 2;
  }
}