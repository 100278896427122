.text-logo {
  color: var(--primary004);

  &__mid {
    font-weight: bold;
  }

  &--small {
    font-size: 1rem;
  }

  &--large {
    font-size: 1.8125rem;
    color: var(--black);
  }
}
