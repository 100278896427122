@import './src/themes/variables';

.cards {
  box-shadow: var(--box-shadow001);
  padding-bottom: 46px;

  &--withBg {
    padding-bottom: 10px;

    @media screen and (min-width: $mq-md) {
      padding-bottom: 94px;
    }
  }

  &--bannerView {
    max-width: var(--media-lg);
    margin: 0 auto;
    padding: 39px 20px 25px 20px;
    box-shadow: none;

    @media screen and (min-width: $mq-sm) {
      display: flex;
    }

    @media screen and (min-width: $mq-lg) {
      padding: 64px 0 44px 0;
    }

    .cards__wrapper,
    .cards__banner-image {
      flex-grow: 1;
    }

    &--columns {
      .cards__wrapper {
        @media screen and (min-width: $mq-md) {
          display: grid;
          grid-template-columns: 45% 55%;
        }
      }
    }

    .cards__items {
      display: block;
      padding: 0;

      > div {
        margin-left: 0;
      }
    }

    .cards__intro {
      margin: 0 auto;
      background: none;
      box-shadow: none;
      padding: 0;

      @media screen and (min-width: $mq-md) {
        padding: 0 120px 0 0;
      }

      &::before {
        display: none;
      }
    }

    .cards__titles-wrapper {
      text-align: left;

      > h2 {
        color: var(--primary007);
        margin: 0;
        line-height: normal;
      }
    }

    .cards__subtitle {
      margin-top: 8px;
      margin-bottom: 30px;
    }
  }

  &__banner-image {
    display: flex;

    @media screen and (min-width: $mq-sm) {
      padding-left: 20px;
    }

    @media screen and (min-width: $mq-sm) and (max-width: $mq-md) {
      width: 40%;
    }

    > img {
      width: 100%;
      align-self: flex-start;

      @media screen and (min-width: $mq-md) {
        max-width: 595px;
      }
    }
  }

  &__wrapper {
    text-align: center;
    position: relative;
  }

  &__button-section {
    @media screen and (min-width: $mq-sm) {
      margin-top: 0;
    }

    @media (min-width: $mq-md) {
      display: block;
      width: 50%;
      padding-right: 0;
      margin-bottom: 0;

      > button {
        visibility: visible;
      }
    }

    > img {
      max-width: 480px;
      width: 100%;
    }

    &--sticky {
      top: 24px;
      left: 0;
      margin: 24px auto 0 auto;
      text-align: center;
      width: calc(100%);
      z-index: 20;
      position: sticky;
      position: -webkit-sticky;

      @media screen and (min-width: $mq-sm) {
        top: -276px;
        max-width: var(--media-lg);
      }

      @media screen and (min-width: $mq-md) {
        top: -151px;
      }
    }
  }

  &__intro {
    padding: 0 20px;
    background: url("../../../assets/backgrounds/bg_card_title-sm.png") center repeat-x;
    background-size: auto 70px;
    margin: 0 auto;
    box-shadow: var(--box-shadow001);
    margin-bottom: 34px;

    @media screen and (min-width: $mq-sm) {
      position: relative;
    }

    @media screen and (min-width: $mq-md) {
      background-image: url("../../../assets/backgrounds/bg_card_title.png");
      background-size: auto 90px;
      margin-bottom: 56px;
    }

    &::before {
      content: '';
      box-shadow: var(--box-shadow003);
      height: 10px;
      top: 0;
      left: 0;
      right: 0;
      position: absolute;
    }

    &--alternative {
      background: none;
      padding-top: 34px;
      max-width: var(--media-lg);
      margin-bottom: 0;
      box-shadow: none;
      display: flex;
      flex-flow: column-reverse;

      @media screen and (min-width: $mq-sm) and (max-width: $mq-md) {
        flex-flow: wrap;
      }

      @media screen and (min-width: $mq-md) {
        flex-flow: initial;
      }
      &::before {
        display: none;
      }
    }
  }

  &__titles-wrapper {
    text-align: center;
    flex: auto;

    &.cards__titles-wrapper--alternative {
      text-align: left;
      padding-bottom: 20px;

      @media (min-width: $mq-md) {
        padding-right: 60px;
        padding-left: 10%;
        max-width: 50%;
        padding-bottom: 50px;
      }
    }
  }

  &__items {
    align-items: center;
    align-self: baseline;
    padding: 0 20px;
    margin: 0 auto;
    max-width: var(--media-lg);

    @media screen and (min-width: $mq-md) {
      justify-content: space-between;
      display: flex;
    }

    @media screen and (min-width: $mq-xl) {
      padding: 0;
    }

    @media screen and (min-width: $mq-sm) and (max-width: $mq-md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__image {
    margin: 20px 0;
  }

  &__title {
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 20px;
    color: var(--white);
    font-size: var(--font-size-title-small);
    line-height: 2.18em;
    font-family: var(--font-family001);

    @media (min-width: $mq-md) {
      line-height: 1.8em;
      font-size: var(--font-size-titles);
    }
  }

  &__titles-wrapper--alternative {
    .cards__title {
      color: var(--primary007);
      padding-right: 130px;
      line-height: var(--line-height-small);

      @media (min-width: $mq-md) {
        padding-right: 0;
      }
    }

    .cards__intro {
      display: flex;
      padding-bottom: 20px;
      margin: 0 auto;
      max-width: var(--media-lg);
      background: none;
    }
  }

  &__button {
    position: absolute;
    top: 0;
    right: 50%;
    margin-right: -80px;
    box-shadow: var(--box-shadow004);

    @media (min-width: $mq-sm) {
      top: 300px;
      right: 300px;
      margin-right: 0;
    }

    @media (min-width: $mq-md) {
      right: 0;
      top: 175px;
    }

    @media (min-width: $mq-lg) {
      right: 15px;
    }

    @media (min-width: $mq-xl) {
      right: -75px;
    }

    &:hover {
      box-shadow: var(--box-shadow004);
    }
  }
}
