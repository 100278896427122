.oldbrowser {
  width: 70%;
  max-width: 49rem;
  margin: auto;
  margin-top: 2rem;
  text-align: center;

  @media screen and (max-width: 599px) {
    width: auto;
    margin: 1rem;
  }
}
