.text-content {
  grid-column: span 9;

  &__highlight {
    font-weight: bold;

    &--alert {
      color: var(--error);
    }

    &--primary {
      color: var(--primary004);
    }
  }
}

.text-link {
  text-decoration: none;
}
