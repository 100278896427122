.language-select-buttons {
  display: flex;
  flex-direction: row;

  &__option {
    margin-right: 1rem;
    width: 10rem;
  }
}

.language-select-toggle {
  padding: 1rem;

  &__toggle {
    display: inline;
    position: relative;
    top: -0.15rem;
    padding: 1rem;
  }
}

.language-select {
  display: grid;
  justify-items: flex-end;
  flex: 1;
}

.react-aria-Select {
    border: none;
    outline: none;
    padding-top: var(--myd-advanced-dropdown-spacing-dropdown-topbottom);
    padding-right: var(--spacing4);
    text-align: right;
    justify-self: end;

    &[data-open] {
      .language-select__icon {
        transform: rotate(180deg);
      }
    }

    .react-aria-Button {
      display: flex;
      justify-content: space-between;
      background: none;
      border: none;
      text-align: center;
      padding: var(--myd-advanced-dropdown-spacing-dropdown-topbottom) var(--myd-advanced-dropdown-spacing-dropdown-leftright);
      gap: var(--myd-dropdown-spacing-dropdown-gap);
      font-weight: var(--myd-dropdown-typography-icon-small-font-weight);
      font-size: var(--myd-input-label-typography-label-font-size);
      line-height: var(--myd-input-label-typography-label-line-height);

      &[data-pressed],
      &[data-hovered],
      &[data-focus-visible]  {
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #E3E8EF;
      }

      &[data-focus-visible]{
        outline-color: var(--myd-dropdown-color-border-focused);
      }
    }

    & span:last-child {
      padding-top: var(--myd-dropdown-spacing-menu-item-topbottom);
    }

    .language-select__icon {
      width: var(--icon-size003);
      height: var(--icon-size003);
    }
  }

  .react-aria-Popover {
    padding: var(--myd-dropdown-spacing-menu-item-topbottom) var(--myd-dropdown-spacing-menu-item-leftright);
    margin-top: calc(-1 * var(--myd-dropdown-spacing-dropdown-topbottom));
    margin-left: var(--myd-advanced-dropdown-spacing-menu-item-hilite-gapleft);
    background-color: white;
    box-shadow: var(--myd-dropdown-shadow-dropdown-list);
    border-radius: var(--myd-dropdown-border-radius-menu-item);


    &[data-focus-visible] {
      outline-color: var(--myd-dropdown-color-border-focused);
    }

    .react-aria-ListBox {
      gap: var(--myd-dropdown-spacing-menu-item-hilite-gapleft);
      border-radius: var(--myd-dropdown-border-radius-menu-item);

      &[data-focus-visible] {
        outline-color: var(--myd-dropdown-color-border-focused);
      }
    }

    .react-aria-ListBoxItem {
      padding-top: var(--myd-dropdown-spacing-menu-item-hilite-righttopbottom);
      padding-right: var(--myd-dropdown-spacing-menu-item-hilite-righttopbottom);
      padding-bottom: var(--myd-dropdown-spacing-menu-item-hilite-righttopbottom);
      padding-left: var(--myd-dropdown-spacing-menu-item-hilite-gapleft);
      font-size: var(--myd-input-label-typography-label-font-size);
      font-weight: var(--myd-font-weight-600);
      width: 73px;
      height: 21px;

      &[data-selected] {
        background: url(../../assets/blue-check.svg) no-repeat;
        background-position: right 15% bottom 50%;
      }

      &[data-focus-visible] {
        outline-color: var(--myd-dropdown-color-border-focused);
      }
    }
  }

