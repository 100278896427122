.person-demographic {
  grid-column: span 12;

  &__description {
    padding-top: 2rem;
  }

  &__phone-container {
    grid-column: span 12;
    display: grid;
    row-gap: 1.5rem;
    grid-template-columns: repeat(12, 1fr);
  }

  &__fields {
    align-items: end;
    column-gap: 1.5rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding-top: 2rem;
    row-gap: 1.5rem;

    & .text-question {
      &__question-text {
        margin-bottom: 0;
      }
    }

    & .option-select {
      &__question {
        padding-bottom: 0;
      }
    }
  }

  & .phone-type-select {
    padding-left: 1rem;
    padding-top: 2.5rem;
    grid-column: span 6;

    @media screen and (max-width: 768px) {
      grid-column: span 12;
      padding: 0;
    }
  }

  &__wand {
    margin: 1rem 0;
    cursor: pointer;
    padding: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.125rem solid var(--primary003);
    border-radius: 1.5rem;
  }
}
