.loading {
  &__container {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &__content {
    position: absolute;
    top: calc(50% - 4rem);
    left: calc(50% - 125px);
  }

  &__spinner {
    margin: auto;
  }
}
