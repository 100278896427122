.text-list {
  display: flex;
  flex-direction: column;
  grid-column: span 12;

  &__text {
    margin: 0.5rem 0;
  }

  &__list {
    column-gap: 1.5rem;
    display: grid;
    padding-inline-start: 1rem;
    padding-left: 3rem;

    &--columned {
      grid-template-columns: repeat(2, 1fr);
      padding-left: 1rem;
    }
  }
}

@media only screen and (max-width: 529px) {
  .text-list {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}
