.insurance {
  grid-column: span 12;

  .insurance-id-wrapper,
  .insurance-provider-wrapper {
    grid-column: span 12;
    column-gap: 1.5rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  &-field__state {
     grid-column: span 12;

     label {
       display: block;
       width: 100%;
     }
  }

  &-field__dob {
    grid-column: span 7;

    @media screen and (max-width: 768px) {
      grid-column: span 12;
    }

    label {
      display: block;
      width: 100%;
    }
  }

  &__description {
    padding-top: 2rem;
  }

  &__text_wrapper {
    max-width: 764px;
    grid-column: span 12;
  }

  &__text,
  &__images_text {
    padding-top: 0;
    font-size: 1em;
    line-height: 1.5em;
  }

  &__wrapper {
    max-width: 764px;
  }

  &__fields {
    align-items: flex-end;
    column-gap: 1.5rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding-top: 2rem;
    row-gap: 1.5rem;

    > div {
      animation: fadeOut 0.25s forwards;
    }

    & .text-question {
      &__question-text {
        margin-bottom: 0;
      }
    }

    & .option-select {
      &__question {
        padding-bottom: 0;
      }
      &__option {
        grid-column: span 4;

        @media screen and (max-width: 529px) {
          grid-column: span 12;
        }
      }
    }
  }
}
