.text-input {
  background-color: var(--white);
  border: 0.25rem solid var(--neutral002);
  box-sizing: border-box;
  font-size: 1.375rem;
  height: 4rem;
  padding: 0.75rem;
  width: 100%;

  &::placeholder {
    color: var(--neutral002);
  }

  &:focus {
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    outline: 0;
  }

  &:required,
  &:invalid {
    box-shadow: none;
  }

  &--filled {
    border-color: var(--primary003);

    &:focus {
      box-shadow: none;
    }
  }

  &--centered {
    text-align: center;
  }

  &--error {
    border-bottom-width: 0.25rem;
    border-bottom-color: var(--error);
    &:focus {
      border-bottom-color: var(--error);
    }
  }
}
