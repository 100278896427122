.start-page {
  text-align: center;
  margin: 2rem;

  &__logo {
    margin-top: 4rem;
  }

  &__intro {
    width: 75%;
    margin: auto;
    margin-top: 4rem;
  }

  &__started {
    width: 15rem;
    margin: auto;
    margin-top: 3rem;
  }

  &__tos {
    margin-top: 4rem;
  }

  &__subtitle {
    width: 50%;
    margin: auto;
    margin-top: 3rem;

    @media screen and (max-width: 480px) {
      width: 90%;
    }
  }
}
