@import './src/themes/variables';

.banner {
  &--withBg {
    background: var(--blue);

    .banner__content {
      padding-bottom: 37px;

      @media (min-width: $mq-md) {
        padding: 89px 0 95px 30px;
      }
    }
  }

  &__wrapper {
    text-align: left;
    align-items: center;
    align-self: baseline;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    color: var(--text-color);
    line-height: var(--line-height-normal);
    font-size: var(--font-size-normal);
    max-width: var(--media-lg);
    margin: 0 auto;

    @media (min-width: $mq-sm) {
      display: grid;
    }
  }

  &__title {
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--primary007);
    font-size: var(--font-size-title-small);
    line-height: var(--line-height-small);
    letter-spacing: -0.01em;
    font-family: var(--font-family001);

    @media (min-width: $mq-sm) {
      padding-right: 20px;
    }

    @media (min-width: $mq-md) {
      font-size: var(--font-size-titles-large);
      line-height: var(--line-height-small);
      margin-top: 25px;
    }
  }

  &__button {
    margin-top: 24px;
  }

  &__image {
    align-self: flex-end;

    > img {
      width: 60%;
      display: block;

      @media (min-width: $mq-sm) {
        width: 100%;
      }
    }
  }

  &__content {
    background: var(--white);
    padding: 0 20px;

    @media (min-width: $mq-sm) {
      background: transparent;
      padding: 0;
    }
  }

  &__logo {
    padding-top: 20px;

    @media (min-width: $mq-md) {
      padding-top: 25px;
    }

    > img {
      max-height: 72px;
      max-width: 220px;

      @media (min-width: $mq-md) {
        max-height: 80px;
        max-width: 248px;
      }
    }
  }

  &__bottom-pattern {
    box-shadow: var(--box-shadow001);
    display: block;
    max-height: 67px;

    > img {
      display: block;
      width: 100%;
    }
  }
}
