.yes-no {
  grid-column: span 12;

  &__btns {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1.5rem;
    row-gap: 3rem;
  }

  &__btn {
    grid-column: span 4;

    @media screen and (max-width: 768px) {
      grid-column: span 5;
    }

    @media screen and (max-width: 530px) {
      grid-column: span 12;
    }
  }

  &__question-text {
    margin-bottom: 2rem;
  }

  &__question-text {
    margin-bottom: 2rem;
  }

  &__question-text {
    margin-bottom: 2rem;
  }
}
