.pathology-details {
  &__buttons {
    padding-bottom: 1.5rem;
  }

  &__del {
    cursor: pointer;
    float: right;
    padding-top: 0.25rem;
    & img {
      height: 1.25rem;
    }
  }

  &__pathology-type {
    margin-bottom: 1rem;
  }

  &__age-display {
    margin: 0;
    padding: 0;
  }

  &__age-aod {
    margin-right: 0.25rem;
  }
}
