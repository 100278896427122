.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: auto;

  &__content-area {
    background: var(--white);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border: none;
    margin: auto;
    margin-top: 6.5%;
    margin-bottom: 6.5%;
    width: 70vw;
    overflow-y: auto;
    padding: 2.5rem;
    position: relative;
  }

  &__backdrop {
    background: rgba(54, 57, 59, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__header {
    text-align: right;
  }

  &__footer {
    padding-top: 2rem;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }

  &__close-icon {
    cursor: pointer;
    display: inline-block;

    img {
      width: 100%;
    }
  }

  &__confirm-btn,
  &__cancel-btn {
    margin-left: 1.5rem;
    max-width: 15rem;
  }
}
