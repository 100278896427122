:root {
  /* spacing */
  --spacing0: 2px;
  --spacing0-negative: calc(var(--spacing0) * -1);
  --spacing1: 4px;
  --spacing1-negative: calc(var(--spacing1) * -1);
  --spacing2: 8px;
  --spacing3: 12px;
  --spacing4: 16px;
  --spacing5: 20px;
  --spacing5-negative: calc(var(--spacing5) * -1);
  --spacing6: 24px;
  --spacing7: 40px;
  --spacing8: 48px;
  --spacing9: 64px;
  --spacing10: 26px;
  --spacing11: 3px;
  --spacing12: 5px;
  --spacing13: 32px;
  --spacing14: 10px;
  --spacing15: 34px;
  --spacing16: 44px;
  --spacing17: 128px;
  --spacing18: 148px;

  /* font vars */
  --font-family: "lato", sans-serif;
  --font-family001: "franklin-gothic-urw", sans-serif;
  --font-family002: "proxima-nova", sans-serif;
  --font-family003: "roboto", sans-serif;

/*  --font-size-smaller: 11px; */
  --font-size-small: 12px;
  --font-size-note: 13px;
  --font-size-text-small: 14px;
  --font-size-normal: 16px;
  --font-size-text: 18px;
  --font-size-text-large: 20px;
  --font-size-text-larger: 24px;
  --font-size-title-small: 32px;
  --font-size-title-medium: 40px;
  --font-size-subtitles: 24px;
  --font-size-titles: 48px;
  --font-size-titles-large: 58px;
  --font-weight-normal: 400;
  --font-weight-bold: 500;
  --font-weight-bolder: 700;

  /* line heights */
  --line-height-small: 1.2em;
  --line-height-smaller: 1.25em;
  --line-height-small-normal: 1.37em;
  --line-height-normal: 1.5em;

  /* letter spacing */
  --letter-spacing-normal: 0.5px;
  --letter-spacing-001: 0.25px;
  --letter-spacing-002: 0.1px;
  --letter-spacing-003: 1%;

  /* base colors */
  --text-color: #58595b;
  --text-color-light: #6d6e6f;
  --text-color-callout: #0579BC;
  --primary001: #043140;
  --primary002: rgba(29, 70, 83, 0.9);
  --primary003: #064775;
  --primary004: #0364a2;
  --primary005: #08a2d3;
  --primary006: #e6eef4;
  --primary007: #0071ce;
  --primary008: #0063b5;
  --primary009: #0579bc;
  --neutral001: #36393b;
  --neutral002: #e0e0e0;
  --neutral003: #f2f2f2;
  --neutral004: #d3d7d9;

  --gray-700: #42474e;

  --dark-blue: #001e2e;
  --white: #fff;
  --black: #36393b;
  --black002: #3a3b3c;
  --gray: #d7d9db;
  --gray001: #a7acb3;
  --gray002: #625b71;
  --blue: #f2faff;
  --light-gray: #ededed;
  --lighter-gray: #f5f5f5;
  --blue-light: #f2f8fd;
  --blue-lighter: #e6f1fa;
  --border-color: var(--neutral004);
  --invalid-color: #a15c07;

  /* overlays for modals */
  --overlay: rgba(224, 224, 224, 0.8);
  --overlay-modal: rgba(54, 57, 59, 0.6);
  --overlay-moda001: rgba(245, 245, 245, 0.6);

  /* shadows */
  --box-shadow: 0.3rem 0.3rem 0.25rem 0.0625rem rgba(0, 0, 0, 0.1);
  --box-shadow001: 0 4px 4px rgba(0, 0, 0, 0.25);
  --box-shadow002: 0 2px 4px rgba(0, 0, 0, 0.15);
  --box-shadow003: inset 0 10px 10px -5px rgba(0, 0, 0, 0.25);
  --box-shadow004: 0px 5px 20px rgba(0, 0, 0, 0.4);
  --box-shadow005: 0px 2px 20px rgba(0, 0, 0, 0.3);
  --box-shadow006: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  --box-shadow-007: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);

  /* borders */
  --border-width-001: 1px;
  --border-width-002: 2px;
  --border-width-004: 4px;
  --border-radius: 4px;
  --border-radius001: 6px;
  --border-radius002: 12px;
  --border-radius003: 15px;
  --border-radius004: 50px;
  --border-001: var(--border-width-001) solid var(--primary009);
  --border-002: var(--border-width-001) solid var(--gray);
  --border-003: var(--border-width-002) solid var(--primary009);

  /* error colors */
  --error: #a51d1e;
  --error-light: #a42e28;

  /* media queries */
  --media-xl: 1440px;
  --media-lg: 1200px;
  --media-md: 960px;
  --media-sm: 700px;
  --media-xs: 300px;
  --max-chatbot-width: 768px;

  --animation-time: 0.25s;
  --animation-time-modal: 0.8s;

  --icon-size001: 37px;
  --icon-size002: 36px;
  --icon-size003: 14px;

  --image-size001: 144px;
  --image-size002: 48px;
}
