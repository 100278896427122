.context-break-node {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 3rem;
  padding-top: 8vh;
  text-align: center;

  &__message,
  &__sub-message,
  &__footer {
    grid-column: span 12;
  }

  &__continue {
    grid-column: 5 / span 4;

    @media screen and (max-width: 768px) {
      grid-column: 4 / span 6;
    }

    @media screen and (max-width: 530px) {
      grid-column: span 12;
    }
  }
}
