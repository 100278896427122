* {
  // font-family: var(--myd-font-sans-inter);
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  color: var(--black);
  font-size: 1rem;
}

fieldset {
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

a {
  color: var(--primary004);
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInModalInAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
