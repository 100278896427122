.breast-cancer-details {
  &__detail {
    padding: 0;
    margin: 0;
  }

  &__detail-label {
    margin-right: 0.25rem;
  }
}
