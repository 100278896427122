@import './src/themes/variables';

.accordion {
  background: var(--white);
  margin-bottom: 35px;

  &__more-link {
    max-width: 165px;
    color: var(--white);
    border: 0;
    text-align: center;
    letter-spacing: -0.01em;
    background-color: var(--primary007);
    border-radius: var(--border-radius);
    padding: 15px;
    transition: all 0.25s ease;
    font-size: var(--font-size-normal);
    font-family: var(--font-family001);
    font-weight: var(--font-weight-bold);
    line-height: normal;
    display: block;
    margin: 0 auto;
    text-decoration: none;

    &:focus,
    &:hover {
      box-shadow: none;
      background-color: var(--primary008);
    }
  }

  &__title-wrapper {
    background: url("../../../assets/backgrounds/bg_accordion_title.png") center repeat-x;
    background-size: var(--media-md) 200px;
    text-align: center;
    box-shadow: var(--box-shadow001);
    position: relative;
    padding: 24px 45px 40px 45px;

    @media screen and (min-width: $mq-md) {
      background-size: auto 235px;
      padding: 50px 20px;
    }

    &::before {
      content: "";
      box-shadow: var(--box-shadow003);
      height: 10px;
      top: 0;
      left: 0;
      right: 0;
      position: absolute;
    }
  }

  &__title,
  &__subtitle {
    color: var(--white);
    font-family: var(--font-family001);
  }

  &__title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-title-small);
    line-height: var(--line-height-normal);
    letter-spacing: 0.015em;
    margin: 0;

    @media screen and (min-width: $mq-md) {
      font-size: var(--font-size-titles);
    }
  }

  &__subtitle {
    font-size: var(--font-size-normal);
    line-height: var(--line-height-normal);
    margin: 0;

    @media screen and (min-width: $mq-md) {
      font-size: var(--font-size-text);
      margin: 16px 0;
    }
  }

  &__items {
    max-width: var(--media-lg);
    margin: 0 auto;
    list-style: none;
    top: -25px;
    padding: 0 20px;
    position: relative;

    @media screen and (min-width: $mq-xl) {
      padding: 0;
    }
  }
}

.accordion-item {
  box-shadow: var(--box-shadow002);
  background: var(--white);
  border-radius: 6px;
  margin-bottom: 15px;
  padding: 14px 20px;

  &__title {
    font-size: var(--font-size-normal);
    font-family: var(--font-family001);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-small);
    letter-spacing: -0.01em;
    color: var(--primary007);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    cursor: pointer;
    position: relative;

    @media screen and (min-width: $mq-md) {
      font-size: var(--font-size-text-large);
    }

    &-icon {
      width: 25px;

      > svg {
        width: 16px;
        height: 20px;
        color: var(--text-color);
        top: -5px;
        position: relative;
        margin-left: 5px;
        transform: rotate(-180deg);
        transition: all 0.25s ease;
        transition-delay: 0.25s;

        @media (prefers-reduced-motion) {
          transform: none;
          transition: none;
        }
      }
    }
  }

  &__content-wrapper {
    line-height: var(--line-height-normal);
    font-family: var(--font-family001);
    transition: max-height 0.5s ease-out;
    max-height: 0;
    box-sizing: content-box;
    overflow: hidden;
  }

  &__content {
    padding: 15px 0;

    @media screen and (min-width: $mq-lg) {
      padding: 15px 80px 15px 0;
    }
  }

  &--active {
    .accordion-item__content-wrapper {
      max-height: 500px;
    }

    .accordion-item__title-icon {
      > svg {
        top: 5px;
        transform: rotate(0deg);
        transition-delay: 0s;

        @media (prefers-reduced-motion) {
          transform: none;
          top: 0;
        }
      }
    }
  }
}
