.dialog-frame {
  &__backdrop {
    background: rgba(54, 57, 59, 0.6);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: +1;
  }

  &__container {
    background: var(--white);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    position: fixed;
    border: none;
    margin: 0;
    width: 80%;
    top: 10%;
    left: 10%;
    height: 80vh;
    z-index: +2;
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: var(--white);
  }

  &__close-button {
    cursor: pointer;
    padding: 1rem;
    text-align: right;
  }

  &__content {
    height: 100%;
  }

  &__frame {
    border: 0;
    width: 100%;
    overflow: auto;
    height: calc(100% - 5.15rem);
  }

  &__components {
    padding: 0 1.5rem;
    box-sizing: border-box;
  }
}
