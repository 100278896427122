.image-upload {
  grid-column: span 6;

  @media screen and (max-width: 529px) {
    grid-column: span 12;
  }

  &__error {
    color: var(--error-light);
  }

  &__validation-error {
    text-align: left;
    float: left;
    margin: 8px 0;
  }

  &__required-label {
    text-align: right;
    float: right;
  }

  &__label-text {
    width: 100%;
  }

  &__placeholder {
    margin-top: 8px;
    cursor: pointer;
    background: rgba(224, 224, 224, 0.8);
    width: 100%;
    height: 240px;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    &:hover {
      background: rgba(224, 224, 224, 0.5);
      transition: all 0.2s linear;

      .image-upload__placeholder-svg {
        color: rgba(6, 71, 117, 0.8);
      }
    }

    &-icon {
      text-align: center;
      padding-top: 50px;
    }
  }

  &__image {
    max-width: 100%;
    object-fit: cover;
  }

  &__placeholder-svg {
    color: rgba(6, 71, 117, 1);
    transition: all 0.2s linear;
    width: 103px;
    height: 95px;
  }

  &__remove {
    color: rgba(204, 204, 204, 1);
    z-index: 1;
    position: absolute;
    bottom: 15px;
    left: 15px;
    transition: all 0.2s linear;

    &:hover {
      color: rgba(204, 204, 204, 0.8);
    }

    &-svg {
      width: 15px;
      height: 15px;
    }
  }

  input[type="file"] {
    display: none;
  }
}
