@import './src/themes/variables';

.footer {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  top: -1.5rem;
  z-index: -1;

  @media screen and (max-width: 599px) {
    flex-direction: column;
    align-items: center;
  }

  &.alternative-style {
    display: block;
    padding: 0;
    top: 0;
    z-index: 0;
  }

  &__left {
    margin-right: 2rem;

    .footer__logo {
      object-fit: cover;
      max-height: 75px;
    }
  }

  &__right {
    @media screen and (max-width: 768px) {
      margin-top: 0.5rem;
      text-align: center;
    }
  }

  &__bottom,
  &__top {
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (min-width: $mq-md) {
      display: flex;
    }
  }

  &__top {
    padding: 0 20px;
    height: 0;

    @media screen and (min-width: $mq-md) {
      box-shadow: var(--box-shadow003);
      padding: 30px 22px 16px 22px;
      height: auto;
    }

    .footer__logo-link {
      top: -45px;
      position: relative;

      @media screen and (min-width: $mq-md) {
        margin-top: 0;
        position: initial;
      }
    }

    .footer__logo {
      max-width: 122px;

      @media screen and (min-width: $mq-md) {
        max-width: 270px;
      }
    }

    > .title {
      font-size: var(--font-size-title-small);
      font-weight: normal;
      margin: 0;
      color: var(--text-color);
      display: none;

      @media screen and (min-width: $mq-md) {
        display: initial;
      }
    }
  }

  &__bottom {
    color: var(--white);
    box-shadow: var(--box-shadow003);
    padding: 15px;
    background-color: var(--primary007);
  }

  &__nav,
  &__sub_nav {
    list-style: none;
    margin: 0;
    padding: 0;

    &_link {
      text-decoration: none;
      color: var(--black);
      display: block;
      cursor: pointer;
      margin-bottom: 20px;
    }
  }

  &__menu-wrapper {
    box-shadow: var(--box-shadow003);
    padding: 47px 0 20px 0;
    overflow: hidden;

    @media screen and (min-width: $mq-md) {
      background: var(--light-gray);
    }
  }

  &__menu {
    max-width: var(--media-lg);
    margin: 0 auto;
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 60px;

    @media screen and (min-width: $mq-md) {
      margin-bottom: 0;
    }

    > li {
      cursor: pointer;
      font-family: var(--font-family001);
      width: 43%;
      padding-left: 20px;

      @media screen and (min-width: $mq-md) {
        width: auto;
        margin-left: 62px;
        padding: 10px;
      }

      .footer__nav_link {
        font-weight: var(--font-weight-bold);
        font-family: var(--font-family001);
        color: var(--primary007);
        text-transform: uppercase;

        @media screen and (min-width: $mq-md) {
          text-transform: none;
        }
      }
    }

    &.simpleView {
      box-shadow: none;
      width: auto;
      display: inline-block;
      margin-bottom: 0;

      @media screen and (min-width: $mq-md) {
        display: flex;
      }

      &:first-child {
        margin-left: 0;
      }

      > li {
        margin-left: 15px;
        font-weight: normal;
        line-height: normal;
        cursor: pointer;
        font-size: var(--font-size-small);
        display: inline-block;
        width: auto;
        max-width: none;
        padding-left: 0;

        &:first-child {
          margin-left: 0;

          &:before {
            display: none;
          }
        }

        > a {
          color: var(--white);
          margin-bottom: 0;
          text-transform: none;
          display: inline;

          @media screen and (min-width: $mq-md) {
            display: inline-block;
          }
        }

        &:before {
          content: "|";
          color: var(--white);
          margin-right: 15px;
          display: none;

          @media screen and (min-width: $mq-md) {
            display: inline-block;
          }
        }
      }
    }
  }

  &__sub_nav {
    > li {
      font-weight: normal;
    }
  }

  .social-menu {
    list-style: none;
    float: right;
    padding: 0 20px 0 0;
    margin: 0;

    @media screen and (min-width: $mq-md) {
      margin: 50px 0 0 0;
      float: right;
      padding: 0;
    }

    > li {
      display: inline-block;
      border: 2px solid var(--primary007);
      border-radius: 50px;
      padding: 5px 6px;
      margin-left: 5px;
      transition: all 0.25s ease;

      @media screen and (min-width: $mq-md) {
        background: var(--primary007);
        border: none;
        padding: 9px 10px;
        margin-left: 10px;
      }

      &:hover {
        background: var(--primary008);
      }
    }

    &__icon {
      width: 20px;
      height: 20px;
      color: var(--primary007);

      @media screen and (min-width: $mq-md) {
        color: var(--white);
        width: 24px;
        height: 24px;
      }
    }
  }

  &__copyright {
    display: block;
    padding: 10px 0;

    @media screen and (min-width: $mq-md) {
      display: inline-block;
      padding: 0;
    }
  }
}


.myra-footer,
.classic {
  margin-top: auto;
  z-index: 1;

  align-items: unset;
  display: unset;
  padding-left: unset;
  padding-right: unset;
  position: unset;

  @media screen and (max-width: 599px) {
    flex-direction: unset;
    align-items: unset;
  }
}

myd-footer::part(myriad__link) {
  display: none;
}

