.cancer-history-review {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  &__content {
    grid-column: 1/-1;
  }

  &__subtitle {
    margin-top: 2rem;
  }

  &__history-title {
    margin-top: 3rem;
  }

  &__hr {
    margin-bottom: 3rem;
  }

  &__list {
    margin-bottom: 3rem;
  }

  &__add {
    border: 0.25rem solid var(--neutral002);
    padding: 6rem;
    text-align: center;
    background: var(--neutral003);
    margin: 1rem 0 3rem 0;

    @media screen and (max-width: 529px) {
      padding: 2rem;
    }
  }

  &__continue {
    grid-column: span 4;

    @media screen and (max-width: 768px) {
      grid-column: span 5;
    }

    @media screen and (max-width: 530px) {
      grid-column: span 12;
    }
  }
}
