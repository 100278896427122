.countdown-page {
  width: 75%;
  margin: auto;
  margin-top: 3rem;

  &__title {
    margin-bottom: 5rem;
    text-align: center;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &__continue-button {
    margin-right: 1rem;
  }

  &__start-over-button {
    margin-left: 1rem;
  }
}
