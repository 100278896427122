.auxiliary-page {
  text-align: center;

  /**** i don't like this as it is a copy of survey-container ****/
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  width: 100vw;

  @media screen and (max-width: 768px) {
    background-image: url("../../assets/bg_wave_sm.png");
  }

  @media screen and (max-width: 1200px) {
    background-image: url("../../assets/bg_wave_md.png");
  }

  @media screen and (min-width: 1200px) {
    background-image: url("../../assets/bg_wave_lg.png");
    background-size: 100vw 25%;
    background-position: bottom;
  }
  /**************************************************************/

  &__title {
    margin: 2.5rem;
  }

  &__message {
    margin: 2.5rem;
    word-wrap: break-word;
  }

  &__button {
    width: 80%;
  }

  &__link {
    text-decoration: none;
  }
}
