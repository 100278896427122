@import './src/themes/variables';
$bubble-radius: var(--spacing3);

:root {
  --nav-header-height: 80px;
}

@mixin fadeOutAnimation {
  opacity: 1;
  animation: fadeOut var(--animation-time) linear;
}

@mixin button {
  border-radius: var(--border-radius001);
  background: var(--primary009);
  border: var(--border-001);
  padding: var(--spacing3) var(--spacing6);
  grid-column: initial;
  margin: 0;

  &:focus,
  &:hover {
    box-shadow: none;
  }

  .text {
    font-size: var(--font-size-text);
    color: var(--white);
  }
}

@mixin whiteButton {
  background: var(--white);
  border: var(--border-001);
  border-radius: var(--border-radius001);

  .text {
    color: var(--primary009);
  }
}

@mixin greyButton {
  text-align: center;
  padding: calc(var(--spacing3) - var(--border-width-001)) var(--spacing4);
  border: var(--border-001);
  font-weight: var(--font-weight-bolder);
  border-radius: var(--border-radius001);
  background: var(--white);
}

@mixin multipleSelectSquare {
  min-width: var(--spacing13);
  min-height: var(--spacing13);
  background: var(--white);
  border: var(--border-width-002) solid var(--gray001);
  border-radius: var(--border-width-002);
}

@mixin circleSelect {
  padding: var(--spacing4);

  &::before {
    content: '';
    border: var(--spacing0) solid var(--gray);
    border-radius: var(--border-radius004);
    height: var(--spacing13);
    width: var(--spacing13);
    background: var(--white);
    margin-right: var(--spacing3);
    display: block;
  }
}

@mixin circleSelectSelected {
  position: relative;

  &::before {
    border: var(--spacing0) solid var(--primary009);
  }

  &::after {
    content: '';
    height: var(--spacing4);
    width: var(--spacing4);
    min-width: var(--spacing4);
    position: absolute;
    left: var(--spacing10);
    top: var(--spacing10);
    background: var(--primary009);
    border-radius: var(--border-radius004);
  }
}

@mixin plusIcon {
  background: url('../assets/icons/plus-1.svg') left top 50% no-repeat;
  padding-left: var(--spacing5);
}

.survey-container {
  &--chatbot {
    background-color: var(--lighter-gray);
    padding-top: var(--spacing4);
    font-family: var(--font-family002);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    --primary004: #ba4f08;
    --primary009: #b12c78;
    --border-001: 1px solid #b12c78;
    --border-003: 1px solid #b12c78;
    --text-color-callout: #b12c78;

    * {
      font-family: var(--font-family002);
      letter-spacing: var(--letter-spacing-001);
    }


    &.survey-container--blue {
      background-image: none;
    }

    .survey-container {
      &__node {
        position: relative;
        margin-top: 0;
        padding: 0;
        max-width: var(--max-chatbot-width);
        padding-bottom: 4rem;
        background-color: var(--lighter-gray);
      }

      &__main {
        margin-top: 0;
        min-height: auto;
        box-sizing: border-box;
      }

      &__logo {
        display: none;
      }
    }

    .header {
      z-index: 2;
      top: 0;
      position: fixed;
      width: 100%;
    }

    .continue {
      display: flex;
      justify-content: flex-end;

      .continue__button {
        width: auto;
        color: var(--primary009);
        border-radius: var(--spacing3);
        background-color: var(--white);
        border: var(--border-001);
        padding: var(--spacing3) var(--spacing6);
        font-weight: var(--font-weight-normal);

        /* TODO: we should make text sizes configurable for buttons. This is a hack that sets the font size to a fixed size. */
        .text {
          font-size: var(--font-size-normal);
        }

        &.button--selected {
          color: var(--black002);
          box-shadow: none;
          background: var(--white) !important;
          border-radius: var(--spacing3) var(--spacing3) 0 var(--spacing3);
          border-color: var(--border-color);

          div {
            color: var(--black002);
            font-weight: var(--font-weight-normal);
          }
        }
      }
    }

    .page-node__action {
      &--continue {
        display: flex;
        justify-content: flex-end;

        .continue {
          display: flex;

          &__button {
            width: auto;
            padding: var(--spacing2) var(--spacing6) var(--spacing2) var(--spacing6);
            border-radius: var(--border-radius001);
            font-size: var(--font-size-text);

            &:not(:disabled) {
              color: var(--primary009);
            }

            &:not(.button--selected) {
              .text {
                font-size: var(--font-size-text);
                letter-spacing: var(--letter-spacing-002);
              }
            }

            &--selected {
              border-color: var(--border-color);
              background-color: var(--white);
              border-radius: var(--spacing3) var(--spacing3) 0 var(--spacing3);
            }
          }

          img {
            align-self: flex-end;
            margin-left: var(--spacing2);
            max-width: 2.2rem;
          }

          &--primary {
            .continue__button {
              &:not(:disabled) {
                background-color: var(--primary009);
                border: 2px solid var(--primary009);
                color: white;
                label,
                .text {
                  color: white;
                }
              }
            }
          }
        }
      }

      &--modal-trigger {
        .continue {
          &__button {
            &:not(:disabled) {
              &:not(.button--selected) {
                background-color: var(--primary009);
                color: var(--white);
                border-color: var(--primary009);
              }
            }
          }
        }
      }
    }

    .chat-item[data-testid*='you-are-not-a-candidate'],
    .chat-item[data-testid*='may-qualify-for-breast-imaging'],
    .chat-item[data-testid*='thank-you'] {
      .inline-image:last-of-type {
        margin-left: var(--spacing3);
        padding-bottom: var(--spacing1);
      }
    }

    .chat-item[data-testid*='thank-you'],
    .chat-item[data-testid*='may-qualify-for-breast-imaging'] {
      .inline-image:last-of-type {
        margin-bottom: var(--spacing2);
      }
    }

    .chat-item[data-testid*='chatbot-terms'] {
      .text-link {
        text-decoration: underline;
        font-weight: var(--font-weight-bolder);
      }
    }
    
    .chat-bubble {
      &--sub {
        margin-top: var(--spacing6);
      }
      
      strong,
      b {
        letter-spacing: var(--letter-spacing-002);
      }

      .inOfficeOptionSelect-answerKey {
        .option-select__container {
          .option-select__options {
            flex-direction: row-reverse;
            flex-wrap: wrap;
            row-gap: var(--spacing2);
          }
        }
      }

      .option-select,
      .continue__button {
        .avatar-container {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        &__container {
          display: flex;

          svg {
            margin-left: var(--spacing2);
            padding: 0;
          }
        }

        &__options {
          display: flex;
          column-gap: var(--spacing4);
          row-gap: var(--spacing4);
        }
      }

      .selection-option {
        flex: 0 0 auto;
        min-height: 0 !important;

        &--primary {
          background-color: var(--primary009);
          border: 2px solid var(--primary009);
          color: white;
        }

        &--selected {
          background-color: var(--white) !important;
          border-radius: var(--spacing3) var(--spacing3) 0 var(--spacing3) !important;
          box-shadow: none;

          label {
            div {
              font-weight: var(--font-weight-normal);
              color: var(--black002);
              letter-spacing: var(--letter-spacing-002);
            }
          }
        }
      }

      .inline-image {
        display: inline-block;
        max-width: 100%;
        margin-block: var(--spacing3);
        padding: 0;
      }

      .help-hint {
        &__separator,
        dfn {
          font-style: italic;
        }
        .text {
          font-size: var(--font-size-normal);
        }
      }

      .text-list {
        @media only screen and (max-width: $mq-s) {
          .text-list__list {
            column-gap: 1rem;
            grid-template-columns: repeat(2, 1fr);
          }
        }

        ul {
          margin: 0;
          padding-left: var(--spacing4);
        }
      }

      .option-select {
        .selection-option {
          grid-column: span 6;
          @include whiteButton;
          min-height: auto;
          padding: var(--spacing3) var(--spacing6);

          &--selected {
            cursor: default;
            border-radius: var(--spacing3) var(--spacing3) 0 var(--spacing3);
            padding: var(--spacing4);
            border-color: var(--neutral004);
            box-shadow: none;

            label,
            .text {
              cursor: default;
              color: var(--black002);
              font-weight: var(--font-weight-normal);
              font-size: var(--font-size-normal);
            }
          }

          &__text {
            letter-spacing: var(--letter-spacing-002);
            font-size: var(--font-size-text);
          }

          &--primary {
            background-color: var(--primary009);
            border: 2px solid var(--primary009);
            color: white;
            label,
            .text {
              color: white;
            }
          }
        }

        .selection-option__label {
          padding: 0;
        }
      }

      &--undo {
        background-color: transparent;
        color: var(--primary004);
        border: none;
        box-shadow: none;
        letter-spacing: var(--letter-spacing-normal);
        animation-name: chatbotAppearance;
        animation-duration: var(--animation-time);
        width: auto;
        margin-right: var(--spacing2);

        .text {
          font-size: var(--font-size-normal);
        }
      }
    }

    .chat-bubble.you-are-a-candidate---pink-background {
      background: var(--primary009);
      color: var(--white);
      padding-right: 0;
      padding-left: var(--spacing3);
      
      .inline-image {
        margin: 0;
        margin-block: 0;
        position: relative;
        bottom: 5px;
        right: 7px;
      }

      .text--1 strong {
        display: inline-block;
        width: 85%;
        padding-right: var(--spacing2);
        font-size: var(--font-size-normal);
        font-weight: var(--font-weight-bold);
        line-height: var(--spacing6);
        letter-spacing: 0.25px;
      }
    
      @media (min-width: 375px) {
        padding-left: var(--spacing4);

        .text--1 strong {
          font-size: var(--font-size-text-large);
        }
    
        .inline-image {
          padding-left: var(--spacing1);
        }
      }
    
      @media (min-width: 634px) {
        .inline-image {
          top: 6px;
        }
      }
    }

    .language-select-dropdown select {
      width: auto;
      font-size: var(--font-size-text);
      text-align: right;
      background: url(../assets/chevron-down.svg) right top 53% no-repeat;
      background-size: 1rem 1rem;
      padding: 0 1.25rem 0 1rem;
    }

    .video {
      &__player {
        width: 100%;
        height: 168px;
        overflow: hidden;

        @media screen and (min-width: $mq-s) {
          height: 240px;
        }

        @media screen and (min-width: $mq-sm) {
          height: 378px;
        }

        // !important used to overwrite players inline styles
        img {
          border: 0 !important;
          height: 100% !important;
          width: 100% !important;
        }
      }
    }

    .countdown-page {
      &__title {
        margin-bottom: 3rem;
      }

      &__timing-out,
      &__seconds {
        display: block;
        line-height: var(--line-height-small);
        text-align: center;
        letter-spacing: var(--letter-spacing-normal);
      }

      &__timing-out {
        font-size: var(--font-size-normal);
        font-weight: normal;
      }

      &__seconds {
        font-size: var(--font-size-subtitles);
        margin-top: var(--spacing4);
      }

      &__actions {
        flex-direction: row-reverse;
        gap: 1rem;
      }

      &__continue-button {
        @include button;
        margin: 0;
      }

      &__start-over-button {
        @include whiteButton;
        margin: 0;

        .text {
          font-size: var(--font-size-normal);
        }
      }
    }
  }

  .endpoint-call-node__text {
    display: none;
  }

  .loading {
    &__container {
      z-index: auto;
      background: var(--overlay-moda001);
    }

    &__spinner {
      > img {
        @media screen and (max-width: $mq-s) {
          max-width: 70% !important; // overwrite inline style
        }
      }
    }
  }

  .text-link {
    color: var(--text-color-callout);
    &--callout {
      display: block;
      text-align: center;
      font-size: var(--font-size-text-larger);
      font-weight: var(--font-weight-bolder);
      line-height: var(--spacing7);
      text-decoration-line: underline;
      text-decoration-thickness: 0.5px;
      text-underline-offset: var(--spacing1);
    }
  }

  .text-content {
    &__highlight {
      &--centered {
        display: block;
        text-align: center;
        font-weight: var(--font-weight-normal);

        strong {
          font-weight: var(--font-weight-bold);
        }
      }
    }
  }
}

.chat-modal-node {
  .page-node,
  .cancer-history-node__content > div,
  .cancer-history-node__content .option-select__container,
  > .subtitle,
  > .relative-selector,
  .continue__button {
    @include fadeOutAnimation;
  }

  .personalInformation-ancestry-answerKey {
    .option-select__options {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 6px;

      @media screen and (min-width: $mq-xs-s) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .selection-option--checkbox {
      grid-column: span 1;
    }
  }

  .page-node__content {
    grid-column-gap: 0;
    row-gap: var(--spacing4);
  }

  .cancer-history-node__subtitle,
  .cancer-history-review__content .title {
    font-weight: var(--font-weight-bolder);
    font-size: var(--font-size-normal);
    line-height: var(--line-height-small);
  }

  .page-node__subtitle.text,
  .cancer-history-node__subtitle {
    font-weight: var(--font-weight-bolder);
    font-size: var(--font-size-normal);
    margin-top: var(--spacing5-negative);
  }

  .selection-option--checkbox {
    .text {
      font-weight: var(--font-weight-normal);
    }
  }

  .option-select__options {
    row-gap: var(--spacing4);
  }

  .selection-option--checkbox .selection-option__label {
    align-items: center;
    position: relative;

    > svg {
      display: none;
    }

    &::before {
      content: '';
      @include multipleSelectSquare;
      top: var(--spacing12);
      bottom: var(--spacing12);
      left: var(--spacing11);
      right: var(--spacing11);
      margin-right: var(--spacing4);
    }

    .text {
      font-size: var(--font-size-normal);
      font-family: var(--font-family003);
      letter-spacing: var(--letter-spacing-002);
    }
  }

  .selection-option--selected {
    .selection-option__label {
      &::before {
        content: '';
        background: transparent;
        border-color: var(--primary009);
      }
    }
  }

  .selection-option__input.selection-option__input--selected {
    & + svg {
      color: var(--primary009);
      margin-right: var(--spacing4);
      position: absolute;
      display: block;
      width: var(--icon-size002);
      height: var(--icon-size002);
      margin: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .text,
  .text-input,
  .question-text,
  .title--3 {
    font-size: var(--font-size-normal);
    line-height: var(--font-size-text-large);
  }

  .text-input:focus {
    border: var(--border-001);
  }

  .question-text,
  .radio-question__label,
  .title {
    font-size: var(--font-size-text);
    font-weight: normal;
    margin-bottom: var(--spacing2);
  }

  .radio__label .text {
    font-weight: var(--font-weight-bolder);
  }

  .option-select__label-container label {
    width: 100%;
    margin-bottom: 0;
    font-size: var(--font-size-small);
  }

  .text-question__validation-label,
  .option-select__required-label {
    text-transform: lowercase;
    width: 100%;
    text-align: right;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-small);
    font-family: var(--font-family);
    letter-spacing: var(--letter-spacing-003);
    color: var(--text-color-callout);

  }

  .text-question__warning-message {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-small);
    font-family: var(--font-family);
  }

  .text-question {
    &__label {
      + .text-question__validation-label {
        width: auto;
      }
    }

    &__label-container {
      .text-question__label {
        font-size: var(--font-size-small);
        margin-bottom: 0;
        letter-spacing: var(--letter-spacing-003);
        font-family: var(--font-family);
      }
    }
  }

  .colonPolyp-polypRange-answerKey {
    label.text {
      font-size: var(--font-size-small);
      letter-spacing: var(--letter-spacing-003);
      font-family: var(--font-family);
      margin-bottom: var(--spacing1);
      width: 100%;
    }
  }

  .page-node {
    display: block;
  }

  .continue {
    position: initial;
    background: none;
  }

  .continue--fixed,
  .continue--end {
    height: auto;
    display: inline-block;

    @media screen and (max-width: $mq-m) {
      display: inline-block;
    }

    @media screen and (max-width: $mq-s) {
      display: inline-block;
      padding-left: 0;
    }
  }

  .option-select__question {
    margin-bottom: var(--spacing4);
  }

  .option-select:not(.ancestry-answerKey):not(.cancer-answerKey):not(.patient-phoneType-answerKey):not(.patient-phoneTypeOptionSelect-answerKey):not(
      .patient-sex-answerKey
    ):not(.consentHideResults-answerKey)
    .selection-option {
    background: var(--white);
    border: 0;
    box-shadow: var(--box-shadow-007);
    border-radius: var(--border-radius001);

    .option-select__options,
    .selection-option__label {
      display: flex;
      align-items: center;

      .text {
        letter-spacing: var(--letter-spacing-003);
        font-family: var(--font-family003);
      }

      .text--bold {
        font-weight: normal;
      }
    }

    label {
      text-align: left;
      margin: 0;
      display: flex;
      align-items: center;
      @include circleSelect;

      .text {
        line-height: normal;
      }
    }

    &--selected,
    &:focus {
      label {
        @include circleSelectSelected;
      }
    }
  }

  .patient-phoneType-answerKey,
  .patient-phoneTypeOptionSelect-answerKey,
  .patient-sex-answerKey {
    .option-select__options {
      display: flex;
    }

    .option-select__option {
      border: 0;
      box-shadow: none;

      &.selection-option--selected,
      &:focus {
        label {
          @include circleSelectSelected;
          position: relative;

          &::after {
            top: var(--spacing4);
            left: var(--spacing14);
          }
        }
      }
    }

    .option-select__options {
      label {
        text-align: left;
        margin: 0;
        display: flex;
        align-items: center;
        @include circleSelect;
        padding: 0;

        .text {
          line-height: normal;
          font-family: var(--font-family003);
          font-weight: normal;
        }
      }
    }
  }

  .yes-no__btns {
    align-items: center;
    display: flex;

    .yes-no__btn {
      margin-top: 0;
      border: 0;
      background: transparent;
      display: flex;
      align-items: center;
      width: auto;
      @include circleSelect;
      padding: 0;
      box-shadow: none;

      .text {
        color: var(--black002);
        font-weight: var(--font-weight-bold);
        font-family: var(--font-family);
      }

      &.button--selected {
        @include circleSelectSelected;

        &::after {
          left: var(--spacing14);
          top: var(--spacing14);
        }
      }
    }
  }

  .page-node p.help-hint {
    grid-column: span 12;
    margin: 0;

    &:first-of-type {
      margin-top: var(--spacing6);
    }

    .help-hint__definition {
      line-height: var(--line-height-normal);
      font-size: var(--font-size-normal);
      letter-spacing: var(--letter-spacing-normal);
    }
  }

  .page-node__action {
    grid-column: initial;

    @media screen and (max-width: $mq-m) {
      grid-column: initial;
    }

    @media screen and (max-width: $mq-s) {
      grid-column: initial;
    }
  }

  .page-node__content,
  .cancer-history-node__content {
    .continue__button,
    .page-node__action--back,
    .button--secondary:not(.yes-no__btn) {
      @include button;
      border: 0;

      .continue--fixed {
        height: auto;
      }
    }

    .page-node__action--back,
    .button--secondary:not(.yes-no__btn) {
      @include whiteButton;
    }

    .page-node__action--back + .page-node__action {
      margin-left: var(--spacing4);
    }
  }

  .page-node__content {
    .page-node__action {
      margin-top: var(--spacing6);
    }
  }

  .confirmation-modal {
    z-index: 2;
    top: 25%;

    .title {
      font-weight: var(--font-weight-bolder);
    }

    &__content-area {
      padding: var(--spacing6);

      @media screen and (min-width: $mq-sm) {
        width: 50vw;
      }
    }

    &__header {
      position: absolute;
      top: var(--spacing4);
      right: var(--spacing4);
    }

    &__footer {
      justify-content: center;
      padding-top: var(--spacing5);
    }

    &__content-area {
      background-color: var(--lighter-gray);
    }

    &__confirm-btn,
    &__cancel-btn {
      @include button;
      width: auto;
    }

    &__confirm-btn {
      @include whiteButton;
      margin-left: var(--spacing4);
    }

    &__close-icon {
      height: var(--spacing5);
      width: var(--spacing5);
    }
  }

  .cancer-history-node__content,
  .page-node__content {
    .button--secondary:not(.yes-no__btn) {
      margin-top: var(--spacing4);
    }
  }

  .text-question,
  .option-select {
    grid-column: span 12;

    @media screen and (max-width: $mq-s) {
      grid-column: span 12;
    }
  }

  .option-select__question,
  .yes-no__question-text,
  .text--2 {
    margin-bottom: var(--spacing3);

    label {
      line-height: var(--line-height-smaller);
    }
  }

  .text-question__question-text {
    margin-bottom: var(--spacing4);
  }

  .selection-option {
    background-color: transparent;
  }

  .text-input,
  .option-select__select-input,
  .continue__button {
    border: none;
    border: var(--border-width-002) var(--neutral004) solid;
    border-radius: var(--spacing12);
    background: var(--white);
    line-height: normal;
    height: auto;
    padding: var(--spacing4);
    font-weight: var(--font-weight-bolder);
    color: var(--neutral001);
    font-size: var(--font-size-normal);
    font-family: var(--font-family);
    transition: all var(--animation-time) ease;
    letter-spacing: var(--letter-spacing-003);

    &::placeholder {
      color: var(--gray001);
      font-weight: normal;
    }

    &:-ms-input-placeholder {
      color: var(--gray001);
      font-weight: normal;
    }

    &::-ms-input-placeholder {
      color: var(--gray001);
      font-weight: normal;
    }
  }

  .text-input,
  .option-select__select-input {
    min-height: 56px;
  }

  .option-select__select-input {
    font-weight: normal;
    color: var(--gray001);
    background-image: url('../assets/icons/arrow-down-1.svg');
    background-repeat: no-repeat;
    background-position: 95% center;
    background-size: var(--spacing3) 7px;
    background-color: var(--white);
  }

  .option-select__select-input {
    &.option-select__select-input--selected {
      font-weight: var(--font-weight-bolder);
      color: var(--neutral001);
    }

    &:focus {
      border-color: var(--primary009);
      border-bottom-width: var(--border-width-002);
      box-shadow: none;
    }
  }

  .radio-wrapper {
    .radio-question__label {
      width: 100%;
    }

    fieldset {
      display: inline-block;
      width: auto;

      .radio {
        margin-right: var(--spacing2);
      }
    }
  }

  .cancer-history-card,
  .cancer-history-review__add,
  .cancer-history-cards__relative {
    border-width: var(--border-width-001);
    padding: 0;

    .link {
      font-size: var(--font-size-normal);
      font-weight: normal;
      color: var(--primary009);
      text-decoration: none;
    }
  }

  .cancer-history-review {
    margin-top: 0;
    margin-bottom: var(--spacing2);
    column-gap: var(--spacing4);
    row-gap: var(--spacing16);

    &__subtitle {
      display: none;
    }

    .pathology-details {
      &__edit {
        font-weight: var(--font-weight-bolder);
        background: url('../assets/icons/edit.svg') no-repeat 0 var(--spacing11);
        padding-left: var(--spacing5);
      }

      &__buttons {
        display: flex;
        padding-bottom: var(--spacing3);
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row-reverse;
      }

      .text {
        font-family: var(--font-family003);
        letter-spacing: var(--letter-spacing-001);
      }

      p {
        color: var(--gray002);
        font-size: var(--font-size-text-small);
        margin-top: var(--spacing11);
      }
    }

    &__add--family,
    &__add--self {
      border: 0;
      text-align: left;

      > .text {
        display: none;
      }

      .link {
        @include greyButton;
        margin-top: var(--spacing4);
        grid-column: span 12;
      }
    }

    &__content {
      display: grid;
      gap: var(--spacing2);

      .title {
        display: none;
      }
    }

    &__subtitle {
      margin-top: 0;
      margin-bottom: var(--spacing4);
    }

    &__history-title {
      margin: 0;
    }

    &__hr {
      display: none;
    }

    &__list {
      margin-bottom: var(--spacing3);

      .cancer-history-card {
        min-height: auto;
        background: var(--white);
        border: var(--border-003);
        border-radius: var(--border-radius001);

        &s__cards {
          gap: var(--spacing6);
        }

        &s__relative {
          margin-bottom: var(--spacing4);
          display: flex;
          justify-content: space-between;
        }

        &s__del-relative {
          font-weight: var(--font-weight-bolder);
        }

        &__container {
          padding: var(--spacing4);
        }

        &__add {
          padding: 0;
          border: 0;
          text-align: left;
        }

        &.cancer-history-card--add {
          background: transparent;
          border: 0;

          .cancer-history-card__container {
            padding: 0;
          }

          .link {
            @include greyButton;
          }
        }
      }
    }

    &__add {
      margin: 0;
      background: transparent;
    }

    &__continue {
      .continue {
        padding: 0;

        &__button {
          width: auto;
          padding: var(--spacing3) var(--spacing6);
          border-radius: var(--border-radius001);
          color: var(--white);
          border: none;
        }
      }
    }
  }

  &.cancer-history-node {
    .cancer-history-node__content {
      .relative-selector,
      .pathology {
        .option-select__subtitle {
          margin-top: var(--spacing2);
        }
      }

      .pathology-select {
        .option-select__options {
          grid-template-columns: repeat(8, 1fr);

          @media screen and (max-width: $mq-s) {
            grid-template-columns: repeat(12, 1fr);
          }
        }

        .selection-option {
          background: var(--white);
          border: 0;
          box-shadow: var(--box-shadow-007);
          border-radius: var(--border-radius001);

          &.selection-option--x-small {
            @media screen and (max-width: $mq-s) {
              grid-column: span 12;
            }
          }

          label {
            text-align: left;
            margin: 0;
            display: flex;
            align-items: center;
            padding: var(--spacing5) var(--spacing4);
            width: 100%;
            justify-content: space-between;

            &::after {
              @include plusIcon;
              font-weight: var(--font-weight-normal);
              padding-right: var(--spacing4);
              white-space: nowrap;
              color: var(--primary009);
              margin-left: var(--spacing5);
            }

            .text {
              line-height: normal;
              font-family: var(--font-family003);
            }
          }
        }
      }

      .yes-no__question-text {
        margin-bottom: var(--spacing5);
      }
    }
  }

  .option-select + .modal__link {
    font-size: var(--font-size-text);
    font-weight: var(--font-weight-bolder);
    color: var(--primary009);
    @include button;
    @include whiteButton;
    grid-column: span 12;
    text-decoration: none;
  }

  &.breast-cancer-risk {
    .help-hint {
      grid-column: span 12;
    }
  }

  .page-node__action--continue {
    .continue__button {
      &:not(:disabled) {
        &:not(.button--selected) {
          background-color: var(--primary009);
          border-color: var(--primary009);
        }
      }
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .survey-container--chatbot {
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
  }
}

.lang-en {
  .chat-modal-node .pathology-select .selection-option label::after {
    content: 'Add diagnosis';
  }
}

.lang-es {
  .chat-modal-node .pathology-select .selection-option label::after {
    content: 'Añadir diagnóstico';
  }
}
