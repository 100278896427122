.video {
  &--modal {
    margin-bottom: 0;
    width: 35rem;

    @media screen and (max-width: 688px) {
      width: 20rem;
    }

    @media screen and (max-width: 445px) {
      width: 15rem;
    }
  }

  &--modal {
    margin-bottom: 0;
    width: 35rem;

    @media screen and (max-width: 688px) {
      width: 20rem;
    }

    @media screen and (max-width: 445px) {
      width: 15rem;
    }
  }

  &__iframe {
    display: block;
    aspect-ratio: 16 / 9;
    overflow-x: hidden;
    min-width: 100%;

    @media screen and (max-width: 529px) {
      height: 15.875rem;
    }

    @media screen and (max-width: 400px) {
      height: 11rem;
    }

    &--modal {
      height: 100%;
      left: 0;
      margin-left: 1rem;
      max-width: 95%;
      overflow-x: auto;
      position: relative;
      top: 0;
    }
  }

  &__player {
    width: 100%;
    height: 368px;
  }
}
