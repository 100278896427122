.mrn-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing6);

  input {
    text-align: center;
  }

  .title {
    font-size: var(--font-size-title-medium) !important;
  }

  .center {
    align-self: center;
  }

  .wrapper--disabled {
    .continue {
      .continue__button {
        border-color: var(--neutral004);
        color: var(--neutral002);
      }
    }
  }

  .invalid-entry--container {
    display: flex;
    flex-direction: column;
    padding: var(--spacing3);
    border: 2px solid var(--invalid-color);
    border-radius: 0.5rem;
    background-color: white;
    .invalid-entry--title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing3);
      color: var(--invalid-color);
      font-weight: var(--font-weight-bolder);
    }
    .invalid-entry--subtitle {
      color: var(--invalid-color);
      margin-top: var(--spacing2);
      font-weight: var(--font-weight-bolder);
    }
    .invalid-entry--content {
      margin-top: var(--spacing1);
    }
  }
}
