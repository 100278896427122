.link {
  background: transparent;
  border: none;
  color: var(--primary004);
  cursor: pointer;
  display: inline;
  font-weight: normal;
  grid-column: span 4;
  letter-spacing: 0.01em;
  outline: none;
  padding: 0;
  text-decoration: underline;

  &--small {
    font-size: 1rem;
  }

  &--large {
    font-size: 1.375rem;
  }

  &:hover {
    color: var(--primary005);
  }

  &--visited {
    color: var(--primary001);
  }

  &--error {
    // TODO: real error color
    color: red;
  }
}
