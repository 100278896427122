.radio {
  position: relative;
  margin-bottom: 8px;

  &__input {
    visibility: hidden;
  }

  &__label {
    display: flex;
    padding: 0;
    box-shadow: none;
    align-items: flex-start;

    > svg {
      height: 28px;
      width: 28px;
      color: var(--gray);
      position: absolute;
      top: -3px;
      left: 0;
    }

    .text {
      margin-left: 15px;
      font-size: var(--font-size-text-large);
    }
  }

  &--selected {
    .radio__label {
      > svg {
        height: 28px;
        width: 28px;
        color: var(--primary004);
        position: absolute;
        top: -3px;
        left: 0;
      }
    }
  }
}
