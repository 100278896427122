.language-page {
  text-align: center;
  margin: 2rem;

  &__logo {
    margin-top: 4rem;
  }

  &__welcome {
    margin: auto;
    margin-top: 4rem;

    @media screen and (min-width: 769px) {
      width: 75%;
    }
  }

  &__language {
    max-width: 20rem;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;
    margin: auto;
    margin-top: 4rem;
  }
}
