.text-question {
  &--super-small {
    grid-column: span 3;
  }

  &--x-small {
    grid-column: span 4;
  }

  &--small {
    grid-column: span 5;
  }

  &--medium {
    grid-column: span 6;
  }

  &--large {
    grid-column: span 9;
  }

  &--x-large {
    grid-column: span 12;
  }

  @media screen and (min-width: 530px) and (max-width: 768px) {
    &--super-small {
      grid-column: span 4;
    }

    &--x-small {
      grid-column: span 5;
    }

    &--small {
      grid-column: span 6;
    }

    &--medium {
      grid-column: span 12;
    }

    &--large {
      grid-column: span 12;
    }

    &--x-large {
      grid-column: span 12;
    }
  }

  @media screen and (max-width: 529px) {
    &--super-small {
      grid-column: span 12;
    }

    &--x-small {
      grid-column: span 12;
    }

    &--small {
      grid-column: span 12;
    }

    &--medium {
      grid-column: span 12;
    }

    &--large {
      grid-column: span 12;
    }

    &--x-large {
      grid-column: span 12;
    }
  }

  &__question-text {
    margin-bottom: 2rem;
  }

  &__label-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
  }

  &__label {
    text-align: left;
  }

  &__validation-label {
    color: var(--error);
    text-align: right;
  }

  &__warning-message {
    color: var(--error);
    margin-top: 0.75rem;  
  }

  &__text-input {
    grid-column: 1 / 4;
  }

  &__secondary-button {
    margin-top: 2rem;
  }
}
