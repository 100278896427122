$content-padding: 64px;

:root {
  .no-overflow {
    overflow: hidden;
  }
}

.modal {
  position: fixed;
  top: var(--nav-header-height);
  bottom: 0;
  left: 0;
  width: 100vw;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &--animated-close {
    opacity: 0;
    animation: fadeInModalInAnimation var(--animation-time-modal) forwards;
  }

  &--full-screen {
    .modal__content-area {
      margin: 0;
      max-width: none;
      max-height: none;
      position: absolute;
      bottom: 0;
      right: 0;
      width: auto;
      height: auto;
    }

    .modal__footer {
      display: none;
    }
  }

  &__button {
    margin: 1rem 0;
  }

  &__content-area {
    background: var(--white);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border: none;
    margin: 0 1.5rem;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 2.5rem;
    position: relative;
    max-width: 75rem;
    max-height: 100%;

    @media screen and (min-width: 530px) and (max-width: 1199px) {
      max-width: 48rem;
    }

    @media screen and (max-width: 529px) {
      max-width: 33.125rem;
    }
  }

  &__backdrop {
    background: var(--overlay-modal);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__header {
    text-align: right;
  }

  &__footer {
    padding-top: 2rem;
    text-align: right;
  }

  &__close-icon {
    cursor: pointer;
    display: inline-block;
    width: 1.5rem;
    color: var(--primary004);
  }
}
