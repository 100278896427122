/* TODO: add this font */
$chat-font-family: 'Proxima Nova', sans-serif;
$chat-font-normal: normal;
$chat-font-weight: 400;
$chat-line-height: 0.25px;
$chat-text1-size: 1rem;
$chat-text1-line-height: 1.25rem;
$bubble-color: var(--white);
$bubble-pointer-width: var(--spacing5);
$bubble-radius: var(--spacing3);
$border-left-width: var(--spacing6);
$vertical-margin: var(--spacing2);
$chat-item-max-width: 2.2rem;

@mixin chatBubbleVariant($color) {
  color: contrast($color);
  background: $color;

  &:after {
    border-color: $color;
  }
}

@keyframes chatbotAppearance {
  from {
    margin-top: 100vh;
  }

  to {
    margin-top: 0;
  }
}

.chat-container {
  padding: 0 var(--spacing4);

  &:first-child {
    padding-top: var(--spacing4);
  }

  &:last-child {
    padding-bottom: var(--spacing4);
  }

  &--undo {
    opacity: 0;
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -ms-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
  }
}

.chat-content {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing2);
  margin-top: var(--spacing2);

  .chat-item {
    display: flex;

    img {
      align-self: flex-end;
      padding-right: var(--spacing4);
      max-width: $chat-item-max-width;
    }

    &--animate {
      .chat-bubble {
        animation-name: chatbotAppearance;
        animation-duration: 0.25s;
      }
    }

    &--centered {
      display: block;
      text-align: center;

      .chat-bubble--left,
      .chat-bubble--right {
        margin-top: var(--spacing1);
      }
    }
  }

  .chat-bubble {
    padding: var(--spacing4);
    background: $bubble-color;
    position: relative;
    border: 1px solid var(--border-color);
    margin: 0;
    font-family: $chat-font-family;
    font-style: $chat-font-normal;
    font-weight: $chat-font-weight;
    letter-spacing: $chat-line-height;
    width: 100%;

    &:has(.option-select) {
      padding: 0;
    }

    .text--1 {
      font-size: $chat-text1-size;
      line-height: $chat-text1-line-height;
    }

    &:empty {
      display: none;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }

    &--left {
      border-radius: $bubble-radius $bubble-radius $bubble-radius 0;
    }

    &--right {
      border-radius: $bubble-radius $bubble-radius 0 $bubble-radius;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    &--bottom-right {
      margin-bottom: $bubble-pointer-width + $vertical-margin;
      border-radius: $bubble-radius;

      &:after {
        border-width: 0 $border-left-width $bubble-pointer-width 0;
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-bottom-color: transparent !important;
        top: auto;
        right: $bubble-pointer-width;
        bottom: -$bubble-pointer-width;
      }
    }

    &--bottom-left {
      margin-bottom: $bubble-pointer-width + $vertical-margin;
      border-radius: $bubble-radius;

      &:after {
        border-width: 0 0 $bubble-pointer-width $border-left-width;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        top: auto;
        left: $bubble-pointer-width;
        bottom: -$bubble-pointer-width;
      }
    }

    &--borderless {
      flex-wrap: wrap-reverse;
      background: none;
      border: none;
      padding: 0;
    }

    &--video {
      padding: 0;
      margin-left: var(--spacing8);
      border-radius: 0;
    }
  }

  .video {
    display: flex;
    align-items: center;
    justify-content: center;

    &__iframe {
      width: 100%;
      height: 100%;
    }
  }

  .container-after-modal-closed {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .chat-bubble--undo {
      margin-right: var(--spacing1);

      .text--1 {
        font-size: var(--font-size-normal);
        line-height: var(--font-size-normal);
        color: var(--primary009);
      }
    }

    .wrapper {
      background: var(--white);
      padding: var(--spacing4) var(--spacing5);

      display: flex;
      gap: 8px;
      border-radius: var(--spacing3) var(--spacing3) 0 var(--spacing3);
      border: 1px solid var(--neutral004);

      .text {
        font-weight: var(--font-weight-bolder);
        font-size: var(--font-size-text);
        color: var(--black002);

        &:first-letter {
          text-transform: capitalize
        }
      }
    }
  }
}

#scroll-to-bottom {
  height: 0;
}
