.checkbox {
  position: relative;

  &__input {
    visibility: hidden;
  }

  &--selected {
    .checkbox__label > svg {
      height: 21px;
      width: 21px;
      top: 0;
      left: 4px;
    }
  }

  &__label {
    display: flex;
    padding: 0;
    box-shadow: none;
    align-items: flex-start;

    > svg {
      height: 28px;
      width: 28px;
      color: var(--primary004);
      position: absolute;
      top: -3px;
      left: 0;
    }

    .text {
      margin-left: 15px;
    }
  }
}
