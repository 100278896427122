.survey-container__header {
  background-color: white;
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 135px;
  top: 0;
}


myd-header::part(base) {
  padding-left: var(--myd-header-spacing-mobile-left);
  padding-top: var(--myd-header-spacing-mobile-top);
  padding-right: var(--myd-header-spacing-mobile-right);
  padding-bottom: var(--myd-header-spacing-mobile-bottom);
  // chatbot styles for header 
  width: 100%;
}

.header__clinic-name {
  display: inline-block;
  min-width: 0;
  max-width: var(--image-size001);
  max-height: var(--image-size002);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--myd-header-typography-nav-text-selected-default-font-size);
}

.mgh-logo {
  max-width: var(--image-size001);
  max-height: var(--image-size002);
}

.header__clinic-logo {
  max-width: var(--image-size001);
  max-height: var(--image-size002);
  padding-top: var(--spacing1);
}


@media (min-width: 768px) {

  .mgh-logo{
    width: unset;
    height: unset;
  }

  .header__clinic-logo {
    width: unset;
  }

  .header__clinic-name {
    display: inline-block;
    max-width: unset;
    max-height: var(--image-size002);
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }
}