.modal--chatbot.modal {
  right: 0;
  left: 0;
  width: auto;
  z-index: 1;

  .endpoint-call-node {
    /* TODO: https: //mygn.atlassian.net/browse/SIM-190 */
    .title {
      display: none;
    }
  }

  .modal__content-area {
    background: var(--lighter-gray);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    padding: var(--spacing6);
    padding-top: var(--spacing9);
    box-shadow: none;
    max-height: calc(100vh - var(--nav-header-height));

    > div {
      max-width: var(--max-chatbot-width);
      margin-left: auto;
      margin-right: auto;
      padding-bottom: var(--spacing4);
    }
  }

  .modal__header {
    color: var(--primary009);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: var(--spacing6);
    align-items: center;
  }
  .modal__close-icon {
    height: 11px;
    width: 11px;
  }
  .modal__close-text {
    display: inline-block;
    max-width: 40px;
    margin-left: var(--spacing1);
    font-size: 10px;
    cursor: pointer;
    font-weight: var(--font-weight-bolder);
  }
  .modal__backdrop {
    background: var(--lighter-gray);
  }
}

