.cancer-history-cards {
  &__cards {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(12, 1fr);
  }

  &__relative {
    padding-bottom: 1rem;
  }

  &__del-relative {
    margin-left: 2rem;
  }
}
