@import './src/themes/variables';

.grid {
  &__title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-title-small);
    line-height: var(--line-height-normal);
    font-family: var(--font-family001);
    letter-spacing: 0.015em;
    color: var(--primary007);
    margin: 0;

    @media screen and (min-width: $mq-md) {
      font-size: var(--font-size-titles);
    }
  }

  &__items {
    list-style: none;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    padding: 0;

    @media screen and (min-width: $mq-sm) {
      grid-template-columns: repeat(3, 1fr);
      display: grid;
    }

    &.columns-2 {
      @media screen and (min-width: $mq-sm) {
        grid-template-columns: repeat(2, 1fr);
        display: grid;
      }
    }

    &.columns-3 {
      @media screen and (min-width: $mq-sm) {
        grid-template-columns: repeat(3, 1fr);
        display: grid;
      }
    }

    &.columns-4 {
      @media screen and (min-width: $mq-sm) {
        grid-template-columns: repeat(4, 1fr);
        display: grid;
      }
    }
  }
}

.grid__item {
  padding: 12px;
  margin-bottom: 15px;
  color: var(--text-color);

  @media screen and (min-width: $mq-sm) {
    margin-bottom: 0;
  }

  .text--1 {
    font-size: var(--font-size-normal);
    line-height: 1.5em;
  }

  &__content {
    padding: 15px 0;

    @media screen and (min-width: $mq-lg) {
      padding: 15px 80px 15px 0;
    }
  }

  &.cell {
    b {
      padding-bottom: 5px;
      display: inline-block;
    }
  }

  &.label {
    color: var(--primary007);

    .text--1 {
      line-height: 1.2em;
      font-weight: var(--font-weight-bolder);
    }
  }

  &.heading {
    padding: 0 0 0 12px;

    .text--1 {
      font-weight: var(--font-weight-bolder);
      color: var(--primary007);
      font-size: var(--font-size-normal);
    }
  }

  &.empty {
    .text--1 {
      display: none;
    }
  }

  &.mobile-only {
    @media screen and (min-width: $mq-sm) {
      display: none;
    }
  }

  &.desktop-only {
    display: none;

    @media screen and (min-width: $mq-sm) {
      display: block;
    }
  }
}
