.cancer-history-card {
  background-color: var(--white);
  border: 0.25rem solid var(--neutral002);
  grid-column: span 6;
  min-height: 14.5rem;

  @media screen and (max-width: 530px) {
    grid-column: span 12;
  }

  &__container {
    padding: 2.5rem;
  }

  &--add {
    background: var(--neutral003);
  }

  &__add {
    padding: 4rem 1rem;
    text-align: center;
  }
}
