.node-footer {
  grid-column: span 12;
  margin: 3rem 0;

  &__pp-link {
    margin-top: -3rem;
    margin-bottom: 3rem;
    grid-column: span 12;
  }
}
