.button {
  background-color: var(--primary003);
  border: 0.25rem solid var(--primary003);
  border-color: var(--primary003);
  border-style: solid;
  color: var(--white);
  cursor: pointer;
  outline: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;

  &:hover {
    background-color: var(--primary004);
    border-color: var(--primary004);
    box-shadow: var(--box-shadow);
  }

  &:active {
    background-color: var(--primary004);
    border-color: var(--primary004);
  }

  &:focus {
    background-color: var(--primary004);
    border-color: var(--primary004);
    box-shadow: var(--box-shadow);
  }

  &:disabled {
    background-color: var(--neutral002);
    border-color: var(--neutral002);
    color: var(--neutral001);
    cursor: not-allowed;

    &:hover,
    &:focus {
      box-shadow: var(--box-shadow);
    }
  }

  &--secondary {
    background-color: var(--white);
    color: var(--black);

    &:hover {
      background-color: var(--white);
      border-color: var(--primary004);
      box-shadow: var(--box-shadow);
    }

    &:focus {
      background-color: var(--white);
      border-color: var(--primary003);
      box-shadow: var(--box-shadow);
    }

    &:disabled {
      background-color: var(--white);
      border-color: var(--neutral002);

      &:hover {
        background-color: var(--white);
        box-shadow: var(--box-shadow);
      }
    }
  }

  &--selected {
    background-color: var(--primary006);
    color: black;
    border-color: var(--primary001);
  }

  &--error {
    background-color: var(--error);
    border-color: var(--white);
  }

  &--alternative {
    max-width: 165px;
    color: var(--white);
    border: 0;
    letter-spacing: -0.01em;
    background-color: var(--primary007);
    border-radius: var(--border-radius);
    padding: 15px;
    transition: all 0.25s ease;

    .text {
      font-size: var(--font-size-normal);
      font-family: var(--font-family001);
      font-weight: var(--font-weight-bold);
      line-height: normal;
    }

    &:focus,
    &:hover {
      box-shadow: none;
      background-color: var(--primary008);
    }
  }
}
