.criteria-details {
  &__title {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &__guidelines-title {
    margin-bottom: 1rem;
  }

  &__guidelines {
    margin-bottom: 2.5rem;
  }

  &__criteria-title {
    margin-bottom: 2.5rem;
  }
}

.guideline-details {
  &__guideline {
    margin-bottom: 1rem;
  }

  &__reason {
    margin-bottom: 1rem;
  }
}
