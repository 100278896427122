.continue {
  &--fixed {
    @keyframes slideUp {
      from {
        bottom: -5rem;
      }
      to {
        bottom: 0;
      }
    }

    align-content: center;
    animation: slideUp 0.5s ease-in-out;
    background-color: var(--overlay);
    bottom: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1.5rem;
    height: 5rem;
    left: 0;
    padding-right: 1rem;
    position: fixed;
    right: 0;
    z-index: 5;

    @media screen and (max-width: 530px) {
      padding-left: 1rem;
    }

    & .continue__button {
      grid-column: span 4 / -1;
      margin: 1rem 0;

      @media screen and (max-width: 768px) {
        grid-column: span 5 / -1;
      }

      @media screen and (max-width: 530px) {
        grid-column: span 12 / -1;
      }
    }
  }

  &--end {
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    animation: fadeIn 0.5s ease-in-out;
  }
}
