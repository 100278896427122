.title {
  font-style: normal;
  font-weight: bold;

  &--1 {
    font-size: 3rem;
    line-height: 3.5rem;
    letter-spacing: -0.02em;
  }

  &--2 {
    font-size: 2.5rem;
    line-height: 3rem;
    letter-spacing: -0.01em;
    margin-bottom: 1.188rem;
  }

  &--3 {
    font-size: 1.875rem;
    line-height: 2.5rem;
    letter-spacing: -0.01em;

    // @media screen and (min-width: 530px) and (max-width: 768px) {
    //   font-size: 2.5rem;
    //   line-height: 3rem;
    // }
  }

  &--4 {
    font-size: 1.5625rem;
    line-height: 2rem;
    letter-spacing: -0.01em;
  }
}
