.toggle {
  display: inline;
  outline: none;

  &__input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__switch {
    position: relative;
    display: inline-block;
    width: 3.75rem;
    height: 2.125rem;

    &:hover,
    &:focus-within {
      box-shadow: var(--box-shadow); // only applies rectangle box shadow
    }

    &--small {
      width: 2.4rem;
      height: 1.4rem;
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--white);
    border: 0.0625rem solid var(--neutral002);
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &--checked {
      background-color: var(--primary004);

      &:before {
        -webkit-transform: translateX(1.625rem);
        -ms-transform: translateX(1.625rem);
        transform: translateX(1.625rem);
        background-color: var(--white) !important;
      }
    }

    &:before {
      position: absolute;
      content: "";
      height: 1.625rem;
      width: 1.625rem;
      left: 0.2rem;
      bottom: 0.2rem;
      background-color: var(--primary004);
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &--round {
      border-radius: 2.125rem;

      &:before {
        border-radius: 50%;
      }
    }

    &--small {
      &:before {
        width: 0.875rem;
        height: 0.875rem;
      }

      &.toggle__slider--checked {
        &:before {
          -webkit-transform: translateX(0.925rem);
          -ms-transform: translateX(0.925rem);
          transform: translateX(0.925rem);
        }
      }
    }
  }
}
